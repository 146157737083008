import { Injectable } from "@angular/core";
import { createStore, withProps } from "@ngneat/elf";
import { selectEntity, withActiveId, withEntities } from "@ngneat/elf-entities";
import { DefaultService, AvisModel } from "@remainmybox/api";
import { Observable } from "rxjs";
import { UntilDestroy } from "@ngneat/until-destroy";
import { EntityRepository } from "../entity.repository";
import { Filter, Props } from "../filter-components";

export interface Avis extends AvisModel {
  avisKey: string;
}

@UntilDestroy()
@Injectable({ providedIn: "root" })
export class AvisRepository extends EntityRepository<Avis> {
  protected defaultSearchProperties: string[] = ["avisId", "text"];

  constructor(private readonly apiService: DefaultService) {
    super(
      createStore(
        { name: "aviss" },
        withProps<Props<AvisModel>>({}),
        withEntities<Avis, "avisKey">({ idKey: "avisKey" }),
        withActiveId()
      )
    );
  }

  public updateFilter(item: Filter) {
    this.store.update((state) => ({
      ...state,
      filter: item
    }));
    this.fetchAviss();
  }

  public fetchAviss() {
    const filter = this.store.getValue().filter;
    this.apiService
      .searchAvis(this.rsql(filter).build())
      .pipe(this.trackRequest())
      .subscribe((aviss) => this.set(aviss as Avis[]));
  }

  public getByKey(key: string): Observable<AvisModel | undefined> {
    this.apiService.getAvisByKey(key).subscribe((avis) => this.upsert(avis));
    return this.store.pipe(selectEntity(key));
  }

  public saveAvis(key: string | undefined, model: AvisModel) {
    if (key) {
      model.avisKey = key;
    }
    return this.apiService.saveAvis(model).pipe(this.mapUpsert());
  }
}

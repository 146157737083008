<ng-container *transloco="let t">
  <div class="bg-white rounded p-16 flex flex-row gap-8">
    <svg
      class="w-48 h-48 text-red-600"
      fill="none"
      viewBox="0 0 24 24"
      stroke-width="1.5"
      stroke="red"
      aria-hidden="true"
    >
      <path
        stroke-linecap="round"
        stroke-linejoin="round"
        d="M12 9v3.75m-9.303 3.376c-.866 1.5.217 3.374 1.948 3.374h14.71c1.73 0 2.813-1.874 1.948-3.374L13.949 3.378c-.866-1.5-3.032-1.5-3.898 0L2.697 16.126zM12 15.75h.007v.008H12v-.008z"
      />
    </svg>
    <div>
      <h1 class="title text-primary text-base font-semibold leading-6">{{ t(props.title) }}</h1>
      <p class="text-sm">
        {{ t(props.message) }}
      </p>
    </div>
  </div>
</ng-container>

import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Observable } from 'rxjs';
import { Injectable } from '@angular/core';


@Injectable()
export class HttpOrderByInterceptor implements HttpInterceptor {

  intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    // Partners has no updts, all other can make use of ordering
    if (!request.params.has("orderBy") && request.url.includes("api") && !request.url.includes("partners") && request.method === "GET") {
      const newReqest = request.clone({
        params: request.params.set("orderBy", "updts,desc;")
      });
      return next.handle(newReqest);
    }
    return next.handle(request);
  }
}

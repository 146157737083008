import { Component } from "@angular/core";
import { CommonModule } from "@angular/common";
import {SvgIconComponent} from "angular-svg-icon";
import {TranslocoModule} from "@ngneat/transloco";

@Component({
  selector: "lib-header",
  standalone: true,
    imports: [CommonModule, SvgIconComponent, TranslocoModule],
  templateUrl: "./lib-header.component.html",
  styleUrl: "./lib-header.component.css"
})
export class LibHeaderComponent {}

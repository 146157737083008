import { Component, Output, ViewEncapsulation, EventEmitter } from "@angular/core";
import { AbstractValueAccessorComponent } from "../abstract-value-accessor.component";
import { ValueAccessorUtil } from "../value-accessor.util";

@Component({
  selector: 'remainmybox-form-toggle',
  templateUrl: './toggle.component.html',
  styleUrls: ['./toggle.component.scss'],
  providers: [...ValueAccessorUtil.getValueAccessorProvider(ToggleComponent)],
  encapsulation: ViewEncapsulation.Emulated,
})
export class ToggleComponent extends AbstractValueAccessorComponent<string | number> {
  @Output() changes: EventEmitter<boolean> = new EventEmitter<boolean>();

  public onChanged() {
    this.changes.emit();
  }
}

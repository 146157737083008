import {Component, OnInit} from "@angular/core";
import {Title} from "@angular/platform-browser";
import {ActivatedRoute, NavigationEnd, Router} from "@angular/router";
import {TranslocoService} from "@ngneat/transloco";
import {filter, map} from "rxjs";
import {MenuItem} from "ui/sidebar";

@Component({
  selector: "remainmybox-root",
  templateUrl: "./app.component.html",
  styleUrls: ["./app.component.scss"]
})
export class AppComponent implements OnInit {
  title = "remainMybox";
  menuItemsDe: Array<MenuItem> = [
    {
      label: "containerNav.container.title",
      icon: "ph ph-rows",

      submenus: [
        {
          label: "containerNav.container.title",
          submenus: [
            {
              link: "container/fullContainer",
              label: "containerNav.container.fullContainer"
            },
            {
              link: "container/emptyContainer",
              label: "containerNav.container.emptyContainer"
            },
            {
              link: "container/containerSeries",
              label: "containerNav.container.containerSeries"
            },
            {link: "container/avis", label: "containerNav.container.avis"},
            {link: "container/release", label: "containerNav.container.release"}
          ]
        },
        {
          label: "containerNav.operation.title",
          submenus: [
            {link: "container/truck", label: "containerNav.operation.truck"},
            {link: "container/rail", label: "containerNav.operation.rail"},
            {link: "container/manually", label: "containerNav.operation.manually"}
          ]
        }
      ]
    },
    {
      label: "servicesNav.title",
      icon: "ph ph-toolbox",
      submenus: [
        {
          label: "servicesNav.estimate.title",
          submenus: [
            {
              link: "container/estimateSearch",
              label: "servicesNav.estimate.estimateSearch"
            },
            {link: "container/send", label: "servicesNav.estimate.send"},
            {
              link: "container/sendLeasing",
              label: "servicesNav.estimate.sendLeasing"
            }
          ]
        },
        {
          label: "servicesNav.workshop.title",
          submenus: [
            {
              link: "services/repairOrder",
              label: "servicesNav.workshop.repairOrder"
            }
          ]
        },
        {
          label: "servicesNav.trade.title",
          submenus: [
            {
              link: "services/containerTrade",
              label: "servicesNav.trade.containerTrade"
            }
          ]
        }
      ]
    },
    {
      label: "accountingNav.title",
      icon: "ph ph-calculator",
      submenus: [
        {
          label: "accountingNav.price.title",
          submenus: [
            {link: "accounting/repair", label: "accountingNav.price.repair"},
            {link: "accounting/handling", label: "accountingNav.price.handling"},
            {
              link: "accounting/storagePrice",
              label: "accountingNav.price.storagePrice"
            },
            {
              link: "accounting/salaryPrice",
              label: "accountingNav.price.salaryPrice"
            }
          ]
        },
        {
          label: "accountingNav.price.title",
          submenus: [
            {link: "accounting/creation", label: "accountingNav.invoice.creation"},
            {link: "accounting/repair", label: "accountingNav.invoice.repair"},
            {link: "accounting/handling", label: "accountingNav.invoice.handling"},
            {
              link: "accounting/storageInvoice",
              label: "accountingNav.invoice.storageInvoice"
            },
            {
              link: "accounting/storageInvoice",
              label: "accountingNav.invoice.manually"
            }
          ]
        },
        {
          label: "accountingNav.selfBillingInvoice.title",
          submenus: [
            {
              link: "accounting/creation",
              label: "accountingNav.selfBillingInvoice.creation"
            },
            {
              link: "accounting/repair",
              label: "accountingNav.selfBillingInvoice.repair"
            },
            {
              link: "accounting/handling",
              label: "accountingNav.selfBillingInvoice.handling"
            },
            {
              link: "accounting/repairHandling",
              label: "accountingNav.selfBillingInvoice.repairHandling"
            }
          ]
        }
      ]
    },
    {
      label: "controllingNav.title",
      icon: "ph ph-chart-bar",
      submenus: [
        {
          label: "controllingNav.statistics",
          submenus: [
            {link: "", label: "controllingNav.handlings"},
            {link: "", label: "controllingNav.movement"},
            {link: "", label: "controllingNav.rebook"},
            {link: "", label: "controllingNav.repair"},
            {link: "", label: "controllingNav.subcontractor"}
          ]
        }
      ]
    },
    {
      label: "masterdataNav.title",
      icon: "ph ph-database",
      submenus: [
        {
          label: "masterdataNav.accounting.title",
          submenus: [
            {
              link: "masterdata/account",
              label: "masterdataNav.accounting.accounts"
            },
            {
              link: "masterdata/internal-order",
              label: "masterdataNav.accounting.internalOrder"
            }
          ]
        },
        {
          label: "masterdataNav.misc.title",
          submenus: [
            {link: "masterdata/pickup-place", label: "masterdataNav.container.pickupPlace"},
            {link: "masterdata/vessel", label: "masterdataNav.misc.vessel"},
            {link: "masterdata/facility", label: "masterdataNav.system.facility"},
            {link: "masterdata/location-block", label: "masterdataNav.container.locationBlock"},
            {link: "masterdata/terminal", label: "masterdataNav.misc.terminal"}
          ]
        },
        {
          label: "masterdataNav.container.title",
          submenus: [
            {link: "masterdata/cedex", label: "masterdataNav.estimate.cedex"},
            {link: "masterdata/ctr-size", label: "masterdataNav.container.ctrSize"},
            {link: "masterdata/quality", label: "masterdataNav.container.quality"}
          ]
        },
        {
          label: "masterdataNav.partner.title",
          submenus: [
            {link: "masterdata/subcontractor", label: "masterdataNav.partner.subcontractor"},
            {link: "masterdata/customer", label: "masterdataNav.partner.customer"},
            {link: "masterdata/partner", label: "masterdataNav.partner.partner"},
            {link: "masterdata/forwarder", label: "masterdataNav.partner.forwarder"}
          ]
        }
      ]
    },
    {
      label: "interfacesNav.title",
      icon: "ph ph-usb",
      submenus: [
        {
          link: "masterdata/edi-mapping",
          label: "interfacesNav.ediMappings"
        },
        {
          link: "masterdata/edi-message",
          label: "interfacesNav.ediTransfer"
        }
      ]
    }
  ];

  menuItemsEn: Array<MenuItem> = [
    {
      label: "containerNav.container.title",
      icon: "ph ph-rows",

      submenus: [
        {
          label: "containerNav.container.title",
          submenus: [
            {
              link: "container/fullContainer",
              label: "containerNav.container.fullContainer"
            },
            {
              link: "container/emptyContainer",
              label: "containerNav.container.emptyContainer"
            },
            {
              link: "container/containerSeries",
              label: "containerNav.container.containerSeries"
            },
            {link: "container/avis", label: "containerNav.container.avis"},
            {link: "container/release", label: "containerNav.container.release"}
          ]
        },
        {
          label: "containerNav.operation.title",
          submenus: [
            {link: "container/truck", label: "containerNav.operation.truck"},
            {link: "container/rail", label: "containerNav.operation.rail"},
            {link: "container/manually", label: "containerNav.operation.manually"}
          ]
        }
      ]
    },
    {
      label: "servicesNav.title",
      icon: "ph ph-toolbox",
      submenus: [
        {
          label: "servicesNav.estimate.title",
          submenus: [
            {
              link: "container/estimateSearch",
              label: "servicesNav.estimate.estimateSearch"
            },
            {link: "container/send", label: "servicesNav.estimate.send"},
            {
              link: "container/sendLeasing",
              label: "servicesNav.estimate.sendLeasing"
            }
          ]
        },
        {
          label: "servicesNav.workshop.title",
          submenus: [
            {
              link: "services/repairOrder",
              label: "servicesNav.workshop.repairOrder"
            }
          ]
        },
        {
          label: "servicesNav.trade.title",
          submenus: [
            {
              link: "services/containerTrade",
              label: "servicesNav.trade.containerTrade"
            }
          ]
        }
      ]
    },
    {
      label: "accountingNav.title",
      icon: "ph ph-calculator",
      submenus: [
        {
          label: "accountingNav.price.title",
          submenus: [
            {link: "accounting/repair", label: "accountingNav.price.repair"},
            {link: "accounting/handling", label: "accountingNav.price.handling"},
            {
              link: "accounting/storagePrice",
              label: "accountingNav.price.storagePrice"
            },
            {
              link: "accounting/salaryPrice",
              label: "accountingNav.price.salaryPrice"
            }
          ]
        },
        {
          label: "accountingNav.price.title",
          submenus: [
            {link: "accounting/creation", label: "accountingNav.invoice.creation"},
            {link: "accounting/repair", label: "accountingNav.invoice.repair"},
            {link: "accounting/handling", label: "accountingNav.invoice.handling"},
            {
              link: "accounting/storageInvoice",
              label: "accountingNav.invoice.storageInvoice"
            },
            {
              link: "accounting/storageInvoice",
              label: "accountingNav.invoice.manually"
            }
          ]
        },
        {
          label: "accountingNav.selfBillingInvoice.title",
          submenus: [
            {
              link: "accounting/creation",
              label: "accountingNav.selfBillingInvoice.creation"
            },
            {
              link: "accounting/repair",
              label: "accountingNav.selfBillingInvoice.repair"
            },
            {
              link: "accounting/handling",
              label: "accountingNav.selfBillingInvoice.handling"
            },
            {
              link: "accounting/repairHandling",
              label: "accountingNav.selfBillingInvoice.repairHandling"
            }
          ]
        }
      ]
    },
    {
      label: "controllingNav.title",
      icon: "ph ph-chart-bar",
      submenus: [
        {
          label: "controllingNav.statistics",
          submenus: [
            {link: "", label: "controllingNav.handlings"},
            {link: "", label: "controllingNav.movement"},
            {link: "", label: "controllingNav.rebook"},
            {link: "", label: "controllingNav.repair"},
            {link: "", label: "controllingNav.subcontractor"}
          ]
        }
      ]
    },
    {
      label: "masterdataNav.title",
      icon: "ph ph-database",
      submenus: [
        {
          label: "masterdataNav.accounting.title",
          submenus: [
            {
              link: "masterdata/account",
              label: "masterdataNav.accounting.accounts"
            },
            {
              link: "masterdata/internal-order",
              label: "masterdataNav.accounting.internalOrder"
            }
          ]
        },
        {
          label: "masterdataNav.misc.title",
          submenus: [
            {link: "masterdata/facility", label: "masterdataNav.system.facility"},
            {link: "masterdata/location-block", label: "masterdataNav.container.locationBlock"},
            {link: "masterdata/pickup-place", label: "masterdataNav.container.pickupPlace"},
            {link: "masterdata/terminal", label: "masterdataNav.misc.terminal"},
            {link: "masterdata/vessel", label: "masterdataNav.misc.vessel"}
          ]
        },
        {
          label: "masterdataNav.container.title",
          submenus: [
            {link: "masterdata/cedex", label: "masterdataNav.estimate.cedex"},
            {link: "masterdata/quality", label: "masterdataNav.container.quality"},
            {link: "masterdata/ctr-size", label: "masterdataNav.container.ctrSize"}
          ]
        },
        {
          label: "masterdataNav.partner.title",
          submenus: [
            {link: "masterdata/customer", label: "masterdataNav.partner.customer"},
            {link: "masterdata/forwarder", label: "masterdataNav.partner.forwarder"},
            {link: "masterdata/partner", label: "masterdataNav.partner.partner"},
            {link: "masterdata/subcontractor", label: "masterdataNav.partner.subcontractor"}
          ]
        }
      ]
    },
    {
      label: "interfacesNav.title",
      icon: "ph ph-usb",
      submenus: [
        {
          link: "masterdata/edi-mapping",
          label: "interfacesNav.ediMappings"
        },
        {
          link: "masterdata/edi-message",
          label: "interfacesNav.ediTransfer"
        }
      ]
    }
  ];

  constructor(
    private router: Router,
    private titleService: Title,
    private activatedRoute: ActivatedRoute,
    private translocoService: TranslocoService
  ) {
  }

  ngOnInit() {
    this.router.events
      .pipe(
        filter((event) => event instanceof NavigationEnd),
        map(() => {
          let child = this.activatedRoute.firstChild;
          while (child) {
            if (child.firstChild) {
              child = child.firstChild;
            } else if (child.snapshot.data && child.snapshot.data["title"]) {
              return child.snapshot.data["title"];
            } else {
              return null;
            }
          }
          return null;
        })
      )
      .subscribe((data: any) => {
        if (data) {
          this.titleService.setTitle(this.translocoService.translate(data) + " - RemainMyBox");
        }
      });
  }

  get menuItems() {
    return this.translocoService.getActiveLang() === "en" ? this.menuItemsEn : this.menuItemsDe;
  }
}

import { Injectable, HostListener } from '@angular/core';
import { Router } from '@angular/router';
import { TranslocoService } from '@ngneat/transloco';
import { AccountRepository } from './account.repository';
import { CedexRepository } from './cedex.repository';
import { CtrSizetypeRepository } from './ctr-size-repository.repository';
import { CustomerRepository } from './customer/customer.repository';
import { EdiMsgHeadRepository } from './edi/edi-msg-head.repository';
import { FacilityRepository } from './facility.repository';
import { ForwarderRepository } from './forwarder.repository';
import { InternalOrderRepository } from './internal-order.repository';
import { LocationBlockRepository } from './location-block.repository';
import { PartnerRepository } from './partner.repository';
import { PickupPlaceRepository } from './pickup-place.repository';
import { QualityRepository } from './quality.repository';
import { SubcontractorRepository } from './subcontractor.repository';
import { TerminalRepository } from './terminal.repository';
import { VesselRepository } from './vessel.repository';
import { TabsLocalStorageModel } from './tabs.model';

@Injectable({ providedIn: 'root' })
export class TabsService {

  constructor(
    private router: Router,
    private translocoService: TranslocoService,
    private qualityRepository: QualityRepository,
    private ctrSizeTypeRepository: CtrSizetypeRepository,
    private locationBlockRepository: LocationBlockRepository,
    private pickupPlaceRepository: PickupPlaceRepository,
    private partnerRepository: PartnerRepository,
    private subcontractorRepository: SubcontractorRepository,
    private forwarderRepository: ForwarderRepository,
    private cedexRepository: CedexRepository,
    private facilityRepository: FacilityRepository,
    private internalOrderRepository: InternalOrderRepository,
    private vesselRepository: VesselRepository,
    private terminalRepository: TerminalRepository,
    private customerRepository: CustomerRepository,
    private ediMessageHeadRepository: EdiMsgHeadRepository,
    private accountRepository: AccountRepository,
  ) {}

  public isTabsChecked() {
    return localStorage.getItem("tabs") === "true";
  }

  public shouldClose(isDirty: boolean) {
    if (isDirty) {
      return window.confirm(this.translocoService.translate('confirm.discard-changes'));
    }
    return;
  }

  public listenForStorage(update: string) {
    window.addEventListener('storage', () => {
      const storageFlag = localStorage?.getItem(update);
      if (storageFlag === "true") {
        switch(update) {
          case TabsLocalStorageModel.UpdateQuality:
            this.getQualityList();
            break;
          case 'updateCtrSize':
            this.getCtrSizeList();
            break;
          case 'updateLocationBlock':
            this.getLocationBlockList();
            break;
          case 'updatePickupPlace':
            this.getPickupPlaceList();
            break;
          case 'updatePartner':
            this.getPartners();
            break;
          case 'updateSubcontractor':
            this.getSubcontractorList();
            break;
          case 'updateForwarder':
            this.getForwarderList();
            break;
          case 'updateCedex':
            this.getCedexList();
            break;
          case 'updateFacility':
            this.getFacilityList();
            break;
          case 'updateInternalOrder':
            this.getInternalOrderList();
            break;
          case 'updateVessel':
            this.getVesselList();
            break;
          case 'updateTerminal':
            this.getTerminals();
            break;
          case 'updateCustomer':
            this.getCustomerList();
            break;
          case 'updateEDIMessage':
            this.getEdiMsgHeads();
            break;
          case 'updateAccount':
            this.getAccountsList();
            break;
        }
        localStorage.removeItem(update);
        localStorage.removeItem(update + '-ctrl');
      }
    });
  }

  public openDetails(nav1: string, nav2: string, nav3: string, key: string | undefined, isTab?: boolean) {
    const urlTree = this.router.createUrlTree([nav1, nav2, nav3, key]).toString();

    if (isTab) {
      window.open(urlTree, '_blank');
    } else {
      this.router.navigate([nav1, nav2, nav3, key]);
    }
  }

  public addItem(nav1: string, nav2: string, nav3: string, isTab?: boolean) {
    const urlTree = this.router.createUrlTree([nav1, nav2, nav3]).toString();

    if (isTab) {
      window.open(urlTree, '_blank');
    } else {
      this.router.navigate([nav1, nav2, nav3]);
    }
  }

  public setStorage(update: string) {
    localStorage.setItem(update, "true");
    window.close();
  }

  public setStorageOnCtrl(update: string) {
    localStorage.setItem(update + "-ctrl", "true");
  }

  public checkStorageOnCtrl(update: string) {
    return localStorage.getItem(update) === "true"
  }

  public getQualityList(): void {
    this.qualityRepository.fetchQualities();
  }

  public getCtrSizeList() {
    this.ctrSizeTypeRepository.fetchCtrSizeTypes();
  }

  public getLocationBlockList(): void {
    this.locationBlockRepository.fetchLocationBlocks();
  }

  public getPickupPlaceList(): void {
    this.pickupPlaceRepository.fetchPickupPlaces();
  }

  public getPartners(): void {
    this.partnerRepository.fetchPartners();
  }

  public getSubcontractorList(): void {
    this.subcontractorRepository.fetchSubcontractors();
  }

  public getForwarderList() {
    this.forwarderRepository.fetchForwarders();
  }

  public getCedexList(): void {
    this.cedexRepository.fetchCedexs();
  }

  public getFacilityList(): void {
    this.facilityRepository.fetchFacilities();
  }

  getInternalOrderList() {
    this.internalOrderRepository.fetchInternalOrders();
  }

  public getVesselList(): void {
    this.vesselRepository.fetchVessels();
  }

  getTerminals() {
    this.terminalRepository.fetchTerminals();
  }

  getCustomerList(): void {
    this.customerRepository.fetchCustomers();
  }

  getEdiMsgHeads() {
    this.ediMessageHeadRepository.fetchEdiMessageHeads();
  }

  public getAccountsList(): void {
    this.accountRepository.fetchAccounts();
  }
}

import { Component, Input } from "@angular/core";
import { CommonModule } from "@angular/common";
import { UiNavLinkModule } from "@remainmybox/ui/nav-link";
import { TranslocoModule } from "@ngneat/transloco";


export interface MenuItem {
  /**
   * if translate is set to true, the label will be used as translation key
   */
  label: string;
  icon?: string;
  isActive?: boolean;
  /**
   * if not set, entries will be translated by default
   */
  translate?: boolean;
  submenus?: MenuItem[];
  link?: string;
}

@Component({
  selector: "lib-ui-sidebar",
  standalone: true,
  imports: [CommonModule, UiNavLinkModule, TranslocoModule],
  templateUrl: "./ui-sidebar.component.html",
  styleUrl: "./ui-sidebar.component.css"
})
export class UiSidebarComponent {
  isOpened = false;


  _menuItems: MenuItem[] = [];

  @Input() set menuItems(value: MenuItem[]) {
    this.initializeDefaultValues(value);
    this._menuItems = value;
  }

  get menuItems(): MenuItem[] {
    return this._menuItems;
  }

  initializeDefaultValues(items: Array<MenuItem>){
        items.forEach((item)=> {
          item.isActive = false;
          item.translate = true;
          if(item?.submenus?.length) {
            this.initializeDefaultValues(item.submenus);
          }
        })
  }


  toggleSidebar() {
    this.isOpened = !this.isOpened;
    if (!this.isOpened) {
      this.menuItems.forEach((i) => (i.isActive = false));
    }
  }

  activateItem(item: any) {
    if (item.isActive) {
      item.isActive = false;
    } else {
      if (!this.isOpened) {
        this.isOpened = true;
      }

      item.isActive = true;
    }
  }
}

import { Injectable } from "@angular/core";
import { createStore, withProps } from "@ngneat/elf";
import { addEntities, setActiveId, withActiveId, withEntities } from "@ngneat/elf-entities";
import { DefaultService, InternalOrderFacilityMappingModel } from "@remainmybox/api";
import { UntilDestroy, untilDestroyed } from "@ngneat/until-destroy";
import { EntityRepository } from "../entity.repository";
import { forkJoin } from "rxjs";
import { Filter, Props } from "../filter-components";

export interface InternalOrderFacility extends InternalOrderFacilityMappingModel {
  internalOrderFacilityKey: string;
}

@UntilDestroy()
@Injectable({ providedIn: "root" })
export class InternalOrderFacilityRepository extends EntityRepository<InternalOrderFacility> {
  protected defaultSearchProperties: string[] = [];

  constructor(private readonly apiService: DefaultService) {
    super(
      createStore(
        { name: "internal-order-facility" },
        withProps<Props<InternalOrderFacilityMappingModel>>({}),
        withEntities<InternalOrderFacility, "internalOrderFacilityKey">({ idKey: "internalOrderFacilityKey" }),
        withActiveId()
      )
    );
  }

  public updateFilter(item: Filter) {
    this.store.update((state) => ({
      ...state,
      filter: item
    }));
  }

  public fetchInternalOrderFacilities() {
    this.apiService
      .searchInternalOrderFacilityMappings()
      .pipe(untilDestroyed(this))
      .subscribe((result) => this.set(result as InternalOrderFacility[]));
  }

  public prepareForSaving(model: InternalOrderFacilityMappingModel, key?: string) {
    if (key) {
      model.internalOrderFacilityKey = key;
    }
    return this.apiService.saveInternalOrderFacilityMapping(model).pipe(untilDestroyed(this), this.mapUpsert());
  }

  public saveFacilities(facilities: InternalOrderFacilityMappingModel[]) {
    return forkJoin(facilities.map((f) => this.prepareForSaving(f)));
  }

  public createInternalOrderFacility(): void {
    this.store.update(addEntities([{ internalOrderFacilityKey: "" } as InternalOrderFacility]), setActiveId(""));
  }
}

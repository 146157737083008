import { Injectable } from "@angular/core";
import { createStore, withProps } from "@ngneat/elf";
import { withActiveId, withEntities } from "@ngneat/elf-entities";
import { CtrLifeModel, DefaultService } from "@remainmybox/api";
import { UntilDestroy } from "@ngneat/until-destroy";
import { EntityRepository } from "../entity.repository";
import { Filter, Props } from "../filter-components";

export interface CtrLife extends CtrLifeModel {
  ctrLifeKey: string;
}

@UntilDestroy()
@Injectable({ providedIn: "root" })
export class EmptyCtrLifeRepository extends EntityRepository<CtrLife> {
  protected defaultSearchProperties: string[] = ["ctrId"];

  constructor(private readonly apiService: DefaultService) {
    super(
      createStore(
        { name: "ctrLifes" },
        withProps<Props<CtrLifeModel>>({}),
        withEntities<CtrLife, "ctrLifeKey">({ idKey: "ctrLifeKey" }),
        withActiveId()
      )
    );
  }

  public updateFilter(item: Filter) {
    this.store.update((state) => ({
      ...state,
      filter: item
    }));
    this.fetchCtrLifes();
  }

  public fetchCtrLifes() {
    const filter = this.store.getValue().filter;
    this.apiService
      .searchEmptyCtrLifes(this.rsql(filter).build())
      .pipe(this.trackRequest())
      .subscribe((ctrLifes) => this.set(ctrLifes as CtrLife[]));
  }

  public saveCtrLife(key: string | undefined, model: CtrLifeModel) {
    if (key) {
      model.ctrLifeKey = key;
    }
    return this.apiService.saveCtrLife(model).pipe(this.mapUpsert());
  }
}

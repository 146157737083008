import { Injectable } from "@angular/core";
import { createStore, withProps } from "@ngneat/elf";
import { selectEntity, withActiveId, withEntities } from "@ngneat/elf-entities";
import { DefaultService, PartnerModel } from "@remainmybox/api";
import { Observable } from "rxjs";
import { UntilDestroy } from "@ngneat/until-destroy";
import { EntityRepository } from "../entity.repository";
import { Filter, Props } from "../filter-components";

export interface Partner extends PartnerModel {
  partnerKey: string;
}

@UntilDestroy()
@Injectable({ providedIn: "root" })
export class PartnerRepository extends EntityRepository<Partner> {
  protected defaultSearchProperties: string[] = ["partnerId", "text"];

  constructor(private readonly apiService: DefaultService) {
    super(
      createStore(
        { name: "partners" },
        withProps<Props<PartnerModel>>({}),
        withEntities<Partner, "partnerKey">({ idKey: "partnerKey" }),
        withActiveId()
      )
    );
  }

  public updateFilter(item: Filter) {
    this.store.update((state) => ({
      ...state,
      filter: item
    }));
    this.fetchPartners();
  }

  public fetchPartners() {
    const filter = this.store.getValue().filter;
    this.apiService
      .searchPartners(this.rsql(filter).build())
      .pipe(this.trackRequest())
      .subscribe((partners) => this.set(partners as Partner[]));
  }

  public getByKey(key: string): Observable<PartnerModel | undefined> {
    this.apiService.getPartnerByKey(key).subscribe((partner) => this.upsert(partner));
    return this.store.pipe(selectEntity(key));
  }

  public savePartner(key: string | undefined, model: PartnerModel) {
    if (key) {
      model.partnerKey = key;
      this.getAuditProperties(key, model as Partner).subscribe(completeModel => {
        model = completeModel;
      });
    }
    return this.apiService.savePartner(model).pipe(this.mapUpsert());
  }
}

import { Injectable } from "@angular/core";
import { Router, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree, UrlSegment, Route } from "@angular/router";
import { KeycloakService, KeycloakAuthGuard } from "keycloak-angular";

@Injectable({ providedIn: "root" })
export class AuthGuard extends KeycloakAuthGuard {
  constructor(router: Router, keycloakAngular: KeycloakService) {
    super(router, keycloakAngular);
  }



isAccessAllowed(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Promise<boolean> {
  return new Promise((resolve, reject) => {
    let permission;
    if (!this.authenticated) {
      this.keycloakAngular
        .login({
          redirectUri: window.location.origin + state.url
        })
        .catch((e) => console.error(e));
      return reject(false);
    }

    // Get the roles required from the route
    const requiredRoles: string[] = route.data["roles"];

    if (!requiredRoles || requiredRoles.length === 0) {
      permission = true;
    } else {
      if (!this.roles || this.roles.length === 0) {
        permission = false;
      }
      if (requiredRoles.every((role) => this.roles.indexOf(role) > -1)) {
        permission = true;
      } else {
        permission = false;
      }
    }
    if (!permission) {
      this.router.navigate(["/"]);
    }
    resolve(permission);
  });
}
}

export interface SingleColumnFilter {
  query?: string | boolean | number;
  property: string;
}

export interface Filter {
  allColumnsQuery?: string;
  singleColumnFilters?: SingleColumnFilter[];
  //set deleted as any intentionally, because boolean property is lost on the way to the repository for the unknown reason
  deleted?: any;
}

export interface Props<T> {
  filter?: Filter;
  sortBy?: keyof T;
  sortDirection?: "ASC" | "DESC";
}

import { Injectable } from "@angular/core";
import { createStore, withProps } from "@ngneat/elf";
import { selectEntity, withActiveId, withEntities } from "@ngneat/elf-entities";
import { DefaultService, AccountModel } from "@remainmybox/api";
import { Observable } from "rxjs";
import { UntilDestroy } from "@ngneat/until-destroy";
import { EntityRepository } from "../entity.repository";
import { Filter, Props } from "../filter-components";

export interface Account extends AccountModel {
  accountKey: string;
}

@UntilDestroy()
@Injectable({ providedIn: "root" })
export class AccountRepository extends EntityRepository<Account> {
  protected defaultSearchProperties: string[] = ["accountId", "name", "remark"];

  constructor(private readonly apiService: DefaultService) {
    super(
      createStore(
        { name: "accounts" },
        withProps<Props<AccountModel>>({}),
        withEntities<Account, "accountKey">({ idKey: "accountKey" }),
        withActiveId()
      )
    );
  }

  public updateFilter(item: Filter) {
    this.store.update((state) => ({
      ...state,
      filter: item
    }));
    this.fetchAccounts();
  }

  public fetchAccounts() {
    const filter = this.store.getValue().filter;
    this.apiService
      .searchAccounts(this.rsql(filter).build())
      .pipe(this.trackRequest())
      .subscribe((accounts) => this.set(accounts as Account[]));
  }

  public getByKey(key: string): Observable<AccountModel | undefined> {
    this.apiService.getAccountByKey(key).subscribe((account) => this.upsert(account));
    return this.store.pipe(selectEntity(key));
  }

  public saveAccount(key: string | undefined, model: AccountModel) {
    if (key) {
      model.accountKey = key;
      this.getAuditProperties(key, model as Account).subscribe(completeModel => {
        model = completeModel;
      });
    }
    return this.apiService.saveAccount(model).pipe(this.mapUpsert());
  }
}

import { Component, Inject } from "@angular/core";
import { DIALOG_DATA, DialogRef } from "@angular/cdk/dialog";

export interface PopupDialogProps {
  title: string;
  message: string;
  closeButtonText: string;
}

@Component({
  selector: "remainmybox-popup",
  templateUrl: "./popup.component.html"
})
export class PopupComponent {
  constructor(public dialogRef: DialogRef<string>, @Inject(DIALOG_DATA) public props: PopupDialogProps) {}
}

<div *transloco="let t">
  <button [ngClass]="[buttonClasses]"
          (click)="onClick()"
          [disabled]="isDisabled">
    <ng-container *ngIf="icon">
      <span class="mr-1" [ngClass]="[icon, iconSize]"></span>
    </ng-container>
    {{ t(buttonText) }}
  </button>
</div>

import { Injectable } from "@angular/core";
import { Dialog } from "@angular/cdk/dialog";
import { PopupComponent } from "@remainmybox/ui/popup";

@Injectable({ providedIn: "root" })
export class ErrorService {
  constructor(private readonly dialog: Dialog) {}

  /**
   * Opens up a popup notifying user of unexpected error.
   */
  public notifyUnexpectedError(err: Error): void {
    // TODO maybe send errors to somewhere to collect data on error sources?
    this.dialog.open(PopupComponent, {
      data: {
        title: "error.title",
        message: "error.message"
      }
    });
  }
}

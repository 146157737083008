import { forwardRef, Provider } from '@angular/core';
import { NG_VALUE_ACCESSOR } from '@angular/forms';
import { AbstractValueAccessorComponent } from './abstract-value-accessor.component';

export class ValueAccessorUtil {
  /**
   * Retrieves the provider array to create a dumb component as a form control
   */
  static getValueAccessorProvider<T = AbstractValueAccessorComponent>(component: T): Provider[] {
    return [
      {
        provide: NG_VALUE_ACCESSOR,
        useExisting: forwardRef(() => component),
        multi: true,
      },
    ];
  }
}

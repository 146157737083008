import { Injectable } from "@angular/core";
import { createStore, withProps } from "@ngneat/elf";
import { selectEntity, withActiveId, withEntities } from "@ngneat/elf-entities";
import { DefaultService, ForwarderModel } from "@remainmybox/api";
import { Observable } from "rxjs";
import { UntilDestroy } from "@ngneat/until-destroy";
import { EntityRepository } from "../entity.repository";
import { Filter, Props } from "../filter-components";

export interface Forwarder extends ForwarderModel {
  forwarderKey: string;
}

@UntilDestroy()
@Injectable({ providedIn: "root" })
export class ForwarderRepository extends EntityRepository<Forwarder> {
  protected defaultSearchProperties: string[] = ["shortname", "name"];

  constructor(private readonly apiService: DefaultService) {
    super(
      createStore(
        { name: "forwarders" },
        withProps<Props<ForwarderModel>>({}),
        withEntities<Forwarder, "forwarderKey">({ idKey: "forwarderKey" }),
        withActiveId()
      )
    );
  }

  public updateFilter(item: Filter) {
    this.store.update((state) => ({
      ...state,
      filter: item
    }));
    this.fetchForwarders();
  }

  public fetchForwarders() {
    const filter = this.store.getValue().filter;
    this.apiService
      .searchForwarders(this.rsql(filter).build())
      .pipe(this.trackRequest())
      .subscribe((forwarders) => this.set(forwarders as Forwarder[]));
  }

  public getByKey(key: string): Observable<ForwarderModel | undefined> {
    this.apiService.getForwarderByKey(key).subscribe((forwarder) => this.upsert(forwarder));
    return this.store.pipe(selectEntity(key));
  }

  public saveForwarder(key: string | undefined, model: ForwarderModel) {
    if (key) {
      model.forwarderKey = key;
      this.getAuditProperties(key, model as Forwarder).subscribe(completeModel => {
        model = completeModel;
      });
    }
    return this.apiService.saveForwarder(model).pipe(this.mapUpsert());
  }
}

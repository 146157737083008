import { Component, OnInit } from "@angular/core";
import { NavigationItem } from "../models/navigation-item.model";
// import { UserService } from "@remainmybox/auth";
//import { DarkModeService } from "angular-dark-mode"
import { Observable } from "rxjs";
import { Router } from "@angular/router";

@Component({
  selector: "remainmybox-header",
  templateUrl: "./header.component.html",
  styleUrls: ["./header.component.scss"]
})
export class HeaderComponent implements OnInit {
  protected selectedMenuPoint: NavigationItem | undefined;

  // Mock data
  protected navigationItems: NavigationItem[] = [
    {
      name: "TEST1",
      id: 0,
      children: [
        {
          name: "TEST-CHILD1",
          id: 1,
          onClick: (): void => {
            // Do something
          }
        }
      ],
      icon: "container.svg"
    },
    { name: "TEST2", id: 0, children: [], icon: "container.svg" },
    {
      name: "Management",
      id: 0,
      icon: "container.svg",
      children: [
        {
          name: "Acccounts",
          id: 1,
          onClick: (): void => {
            // this.userService.manageAccount();
          }
        }
      ]
    }
  ];

  /*darkMode$: Observable<boolean> = this.darkModeService.darkMode$;

  public darkModeChecked = false;*/

  // Temporary - waiting backend
  protected languages: Array<string> = ["de", "en"];

  // Temporary - waiting backend
  protected selectedLanguage: string | undefined = "de";

  public tabsChecked: boolean | null = false;

  constructor(/*private userService: UserService,*/ /*private darkModeService: DarkModeService,*/ private router: Router) {}

  ngOnInit(): void {
    /*this.darkMode$.subscribe(value => {
      this.darkModeChecked = value;
    });*/

   localStorage.getItem("tabs") === "true" ? this.tabsChecked = true : this.tabsChecked = false;
  }

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  protected search(_input: string): void {
    // ToDo - waiting for backend
  }

  // protected logout() {
  //   this.userService.logout();
  // }

  protected editProfile() {
    // ToDo - waiting for backend
  }

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  // protected updateLanguage(language: string) {
  //   this.userService.updateLanguage(language);
  // }

  /*onToggle(): void {
    this.darkModeService.toggle();
  }*/

  onTabsToggle(): void {
    if(this.tabsChecked) {
      localStorage.setItem('tabs',"false");
      this.tabsChecked = false;
      this.reloadCurrentRoute();
    } else {
      localStorage.setItem('tabs', "true");
      this.tabsChecked = true;
      this.reloadCurrentRoute();
    }
  }

  /*get whiteDarkModeLogo() {
    return this.darkModeChecked ? "assets/images/logo_white.png" : "assets/images/logo.png";
  }*/

  reloadCurrentRoute() {
    const currentUrl = this.router.url;
    this.router.navigateByUrl('/', {skipLocationChange: true}).then(() => {
        this.router.navigate([currentUrl]);
    });
}
}

import { Component, EventEmitter, Input, Output } from "@angular/core";
import { AbstractValueAccessorComponent } from "../abstract-value-accessor.component";

@Component({
  selector: "remainmybox-form-button",
  templateUrl: "./form-button.component.html",
  styleUrls: ["./form-button.component.scss"]
})
export class FormButtonComponent extends AbstractValueAccessorComponent<string | number> {
  @Input() buttonText = "";
  @Input() isDisabled = false;
  @Input() outlined = false;
  @Input() textColor: "blue" | "green" | "red" | "white" = "blue";
  @Input() bgColor:  "bg-blue-light" | "blue" | "green" | "red" | "white" = "white";
  @Input() size: "small" | "medium" | "large" = "medium";
  @Input() icon = "";
  @Input() iconSize = "text-xl";
  @Input() square = false;
  @Input() hasBorder = true;
  @Input() widthFull = false;
  @Input() uppercased = false;

  @Output() btnClick = new EventEmitter();
  /*
  * TODO: need to implement correct sizing for square buttons. Right now it's only one size as below.
  *       Refactor and decompose the buttonClasses method to make it more readable and maintainable.
  * */
  get buttonClasses(): string {
    let classes = "font-button rounded-btn flex items-center justify-center px-4";

    if (this.outlined) {
      if (this.hasBorder) {
        classes += ` border-2 border-${this.textColor} text-${this.textColor} bg-${this.bgColor}`;
      } else {
        classes += ` text-${this.textColor} bg-${this.bgColor}`;
      }
    } else {
      classes += ` bg-${this.textColor} text-white hover:bg-${this.textColor}-hover`;
    }
    if (this.isDisabled) {
      classes += ` disabled:bg-${this.textColor}-disabled disabled:cursor-not-allowed`;
    }
    if (this.square) {
      classes += " w-8 h-8";
    } else {
      classes += " rounded-lg";
    }
    if(this.widthFull) {
      classes += " w-full"
    }
    switch (this.size) {
      case "small":
        classes += " py-0 px-1";
        break;
      case "large":
        classes += " py-4 px-6";
        break;
      default:
        classes += " py-btn-y px-btn-x";
    }

    if (this.uppercased) {
      classes += " uppercase";
    }
    return classes;
  }

  onClick() {
    this.btnClick.emit();
  }
}

import { Component, Input } from "@angular/core";

@Component({
  selector: "remainmybox-nav-link",
  templateUrl: "./nav-link.component.html",
  styleUrls: ["./nav-link.component.scss"]
})
export class NavLinkComponent {
  @Input() public link = "";
  @Input() public translationKey = "";
  @Input() public deactivated = false;
}

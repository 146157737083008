import { Injectable } from "@angular/core";
import { createStore, withProps } from "@ngneat/elf";
import { selectEntity, withActiveId, withEntities } from "@ngneat/elf-entities";
import { DefaultService, TerminalModel } from "@remainmybox/api";
import { Observable } from "rxjs";
import { UntilDestroy } from "@ngneat/until-destroy";
import { EntityRepository } from "../entity.repository";
import { Filter, Props } from "../filter-components";

export interface Terminal extends TerminalModel {
  terminalKey: string;
}

@UntilDestroy()
@Injectable({ providedIn: "root" })
export class TerminalRepository extends EntityRepository<Terminal> {
  protected defaultSearchProperties: string[] = ["terminalId", "name"];

  constructor(private readonly apiService: DefaultService) {
    super(
      createStore(
        { name: "terminals" },
        withProps<Props<TerminalModel>>({}),
        withEntities<Terminal, "terminalKey">({ idKey: "terminalKey" }),
        withActiveId()
      )
    );
  }

  public updateFilter(item: Filter) {
    this.store.update((state) => ({
      ...state,
      filter: item
    }));
    this.fetchTerminals();
  }

  public fetchTerminals() {
    const filter = this.store.getValue().filter;
    this.apiService
      .searchTerminals(this.rsql(filter).build())
      .pipe(this.trackRequest())
      .subscribe((terminals) => this.set(terminals as Terminal[]));
  }

  public getByKey(key: string): Observable<TerminalModel | undefined> {
    this.apiService.getTerminalByKey(key).subscribe((terminal) => this.upsert(terminal));
    return this.store.pipe(selectEntity(key));
  }

  public saveTerminal(key: string | undefined, model: TerminalModel) {
    if (key) {
      model.terminalKey = key;
      this.getAuditProperties(key, model as Terminal).subscribe(completeModel => {
        model = completeModel;
      });
    }
    return this.apiService.saveTerminal(model).pipe(this.mapUpsert());
  }
}

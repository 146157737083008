import { OrArray, Store } from "@ngneat/elf";
import {
  selectActiveEntity,
  selectAllEntities,
  selectEntity,
  setActiveId,
  setEntities,
  upsertEntities
} from "@ngneat/elf-entities";
import { joinRequestResult, trackRequestResult } from "@ngneat/elf-requests";
import { RequestResult } from "@ngneat/elf-requests/src/lib/requests-result";
import { map, Observable, of, switchMap, take } from "rxjs";
import { RsqlBuilder } from "./rsql-builder";
import { Filter } from "./filter-components";
import { UserService } from "@remainmybox/auth";
import { inject } from "@angular/core";

export type EntityResult<T> = RequestResult<unknown> & { data: T[] };

export abstract class EntityRepository<Entity> {
  public readonly all$: Observable<Entity[]>;
  public readonly withResults$: Observable<EntityResult<Entity>>;
  public readonly active$: Observable<Entity>;
  protected abstract defaultSearchProperties: string[];
  protected userService!: UserService;


  protected constructor(
    protected readonly store: Store) {
    this.all$ = this.store.pipe(selectAllEntities());
    this.withResults$ = this.store.pipe(selectAllEntities(), this.withRequestResults());
    this.active$ = this.store.pipe(selectActiveEntity());
    this.userService = inject(UserService);
  }

  protected getCurrentUser(): string | undefined {
    return this.userService.getUserName();
  }

  protected getAuditProperties(key: string | undefined, model: Entity) {
    if (!key) {
      return of(model);
    }

    return this.store.pipe(
      selectEntity(key), // Select entity by key from store
      take(1),
      switchMap(entityFromStore => {
        const completeModel = {
          ...model,
          insts: entityFromStore?.insts,
          updts: entityFromStore?.updts,
          insuid: entityFromStore?.insuid,
          upuid: this.getCurrentUser(),
          lastfunc: entityFromStore?.lastfunc
        };
        return of(completeModel);
      })
    );
  }

  protected set(entities: Entity[]) {
    this.store.update(setEntities(entities));
  }

  protected upsert(entities: OrArray<Partial<Entity>>) {
    this.store.update(upsertEntities(entities));
  }

  /**
   * Takes the result and upserts it into the store.
   * @protected
   * @see map
   */
  protected mapUpsert() {
    return map((entity: OrArray<Partial<Entity>>) => this.upsert(entity));
  }

  public setActive(id: string) {
    this.store.update(setActiveId(id));
  }

  /**
   * Add this to your query to get result information.
   * @returns - A function that adds result information next to the list of entities.
   */
  protected withRequestResults<T>() {
    return joinRequestResult<T>([this.store.name]);
  }

  /**
   * Add this to your API-Call to get tracking.
   * @returns - A function that facilitates the tracking of the request.
   */
  protected trackRequest<T>() {
    return trackRequestResult<T>([this.store.name], { skipCache: true });
  }

  /**
   * Takes a filter and returns a RSQL builder.
   * The client of that method should call "build()" on the RSQL builder to get the RSQL query.
   * If filter contains singleColumnFilters, it will use them to create a RSQL query and combine them with AND.
   * Otherwise, it will use the query property and the defaultSearchProperties to create a RSQL query.
   * @param filter - The filter to be converted.
   * @returns - The RSQL query.
   */
  // TODO: refactor that method, add docs, it's difficult to read and understand it now
  protected rsql(filter: Filter | undefined): RsqlBuilder {
    let rsqlBuilder = new RsqlBuilder();
    let deletedEnabled;
    if (filter?.singleColumnFilters) {
      filter.singleColumnFilters.forEach((columnFilter) => {
        if (typeof columnFilter.query === "string") {
          rsqlBuilder = rsqlBuilder.likeIgnoreCase(columnFilter.property, columnFilter.query);
        } else if (typeof columnFilter.query === "number") {
          rsqlBuilder = rsqlBuilder.eq(columnFilter.property, columnFilter.query);
        } else if (typeof columnFilter.query === "boolean") {
          if (columnFilter.property === "deleted") {
            deletedEnabled = true;
          } else {
            rsqlBuilder = rsqlBuilder.eq(columnFilter.property, columnFilter.query);
          }
        }
      });
      if (!deletedEnabled) {
        this.applyDeletedFilter(filter, rsqlBuilder);
      }
      return rsqlBuilder;
    }
    rsqlBuilder = rsqlBuilder.query(filter?.allColumnsQuery, this.defaultSearchProperties);
    this.applyDeletedFilter(filter, rsqlBuilder);
    return rsqlBuilder;
  }

  private applyDeletedFilter(filter: Filter | undefined, rsqlBuilder: RsqlBuilder) {
    if (filter?.deleted !== undefined) {
      rsqlBuilder.eq("deleted", filter.deleted); // include deleted filter if entity has such property
    }
  }
}

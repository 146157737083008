import { Injectable } from "@angular/core";
import { createStore, withProps } from "@ngneat/elf";
import { selectEntity, withActiveId, withEntities } from "@ngneat/elf-entities";
import { DefaultService, VesselModel } from "@remainmybox/api";
import { Observable } from "rxjs";
import { UntilDestroy } from "@ngneat/until-destroy";
import { EntityRepository } from "../entity.repository";
import { Filter, Props } from "../filter-components";

export interface Vessel extends VesselModel {
  vesselKey: string;
}

@UntilDestroy()
@Injectable({ providedIn: "root" })
export class VesselRepository extends EntityRepository<Vessel> {
  protected defaultSearchProperties: string[] = ["callSign", "name"];

  constructor(private readonly apiService: DefaultService) {
    super(
      createStore(
        { name: "vessels" },
        withProps<Props<VesselModel>>({}),
        withEntities<Vessel, "vesselKey">({ idKey: "vesselKey" }),
        withActiveId()
      )
    );
  }

  public updateFilter(item: Filter) {
    this.store.update((state) => ({
      ...state,
      filter: item
    }));
    this.fetchVessels();
  }

  public fetchVessels() {
    const filter = this.store.getValue().filter;
    this.apiService
      .searchVessels(this.rsql(filter).build())
      .pipe(this.trackRequest())
      .subscribe((vessels) => this.set(vessels as Vessel[]));
  }

  public getByKey(key: string): Observable<VesselModel | undefined> {
    this.apiService.getVesselByKey(key).subscribe((vessel) => this.upsert(vessel));
    return this.store.pipe(selectEntity(key));
  }

  public saveVessel(key: string | undefined, model: VesselModel) {
    if (key) {
      model.vesselKey = key;
      this.getAuditProperties(key, model as Vessel).subscribe(completeModel => {
        model = completeModel;
      });
    }
    return this.apiService.saveVessel(model).pipe(this.mapUpsert());
  }
}

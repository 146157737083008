import { ChangeDetectorRef, Component, ElementRef, HostListener, Input, OnInit, Optional, ViewChild, ViewEncapsulation } from "@angular/core";
import { AbstractValueAccessorComponent } from "../abstract-value-accessor.component";
import { ValueAccessorUtil } from "../value-accessor.util";
import { CompareWithFn } from "@ng-select/ng-select/lib/ng-select.component";
import { ControlContainer } from "@angular/forms";

export type InputSizeType = "small" | "medium" | "large";

@Component({
  selector: "remainmybox-form-select",
  templateUrl: "./form-select.component.html",
  styleUrls: ["./form-select.component.scss"],
  encapsulation: ViewEncapsulation.None,
  providers: [...ValueAccessorUtil.getValueAccessorProvider(FormSelectComponent)]
})
export class FormSelectComponent extends AbstractValueAccessorComponent<string | number> implements OnInit {
  @Input() public inputSize: InputSizeType = "large";
  @Input() public dataSource: any;
  @Input() public optionValue = "";
  @Input() public optionDisplay = "";
  @Input() public selectedItem: any = "";
  @Input() public compareKey: any = "";
  @Input() public translationPrefix?: string;
  @Input() public useLabel = true;
  @Input() public compareWith: CompareWithFn = this.compareItems;
  @Input() includeEmptyOption = false;

  isHovered = false;
  outerDivClasses = "";

  @ViewChild("internalSelectField", { static: true }) internalSelectField: ElementRef<HTMLSelectElement> | undefined;

  constructor(
    protected override changeDetectorRef: ChangeDetectorRef,
    @Optional() protected override _controlContainer: ControlContainer,
    private elementRef: ElementRef
  ) {
    super(changeDetectorRef, _controlContainer);
  }

  ngOnInit() {
    // If this dropdown is inside an accordion item body, add a margin to the outer div
    const accordionElement = this.elementRef.nativeElement.closest('.accordion-item-body');
    const tableElement = this.elementRef.nativeElement.closest('table');
    if (accordionElement && !tableElement) {
      this.outerDivClasses = "mr-4";
    }
  }

  onSelectionChanged(value: any): void {
    if (this.optionValue) {
      this.value = typeof value === "object" ? value[this.optionValue] : value;
    } else {
      this.value = value;
    }
    this.formControl?.setValue(this.value);
    this.onChange(this.value);
    this.changed.emit(this.value);
  }

  /**
   * Marks the control as touched
   */
  onSelectTouched(): void {
    if (this.onTouched instanceof Function) {
      this.onTouched();
    }

    this.blurred.emit();
  }

  @HostListener("mouseleave")
  onMouseLeave() {
    this.isHovered = false;
  }

  @HostListener("mouseenter")
  onMouseEnter(inputSelectField: HTMLSelectElement) {
    if (inputSelectField === this.internalSelectField?.nativeElement) {
      this.isHovered = true;
    }
  }

  get compareItems(): CompareWithFn {
    return (this.compareKey) ? this.compareByCompareKeyFn : this.compareByOptionValueFn;
  }

  get compareByCompareKeyFn(): CompareWithFn {
    return (item1, item2) => item1 && item2 ? item1[this.compareKey] === item2[this.compareKey] : item1 === item2;
  }

  get compareByOptionValueFn(): CompareWithFn {
    return (item1, item2) => item1 && item2 && (this.optionValue) ? item1[this.optionValue] === item2[this.optionValue] : item1 === item2;
  }

}
import { UntilDestroy } from "@ngneat/until-destroy";
import { Injectable } from "@angular/core";
import { createStore, withProps } from "@ngneat/elf";
import {
  selectAllEntities,
  selectEntity,
  setEntities,
  upsertEntities,
  withActiveId,
  withEntities
} from "@ngneat/elf-entities";
import { Observable } from "rxjs";
import { DefaultService, EdiMsgContentArchiveModel } from "@remainmybox/api";
import { EntityRepository } from "../../entity.repository";
import { Filter, Props } from "../../filter-components";

export interface EdiMsgContentArchive extends EdiMsgContentArchiveModel {
  ediMsgContentArchiveKey: string;
}


@UntilDestroy()
@Injectable({ providedIn: "root" })
export class EdiMsgContentArchiveRepository extends EntityRepository<EdiMsgContentArchive> {
  protected defaultSearchProperties: string[] = [];

  constructor(private readonly apiService: DefaultService) {
    super(createStore(
      { name: "ediMsgContentArchive" },
      withProps<Props<EdiMsgContentArchiveModel>>({}),
      withEntities<EdiMsgContentArchive, "ediMsgContentArchiveKey">({ idKey: "ediMsgContentArchiveKey" }),
      withActiveId()
    ));
  }

  public updateFilter(item: Filter) {
    this.store.update(
      (state) => ({
        ...state,
        filter: item
      }));
    this.fetchEdiMsgContentArchives();
  }

  public fetchEdiMsgContentArchives() {
    this.apiService.searchEdiMsgContentArchives().subscribe(result => this.setEdiMsgContentArchives(result));
  }

  private setEdiMsgContentArchives(ediMsgContentArchives: EdiMsgContentArchiveModel[]) {
    this.store.update(
      setEntities(ediMsgContentArchives as EdiMsgContentArchive[])
    );
  }

  getById(ediMsgHeadKey: string): Observable<EdiMsgContentArchive | undefined> {
    this.apiService.getEdiMsgHeadByKey(ediMsgHeadKey).subscribe((item) => this.store.update(upsertEntities(item as EdiMsgContentArchive)));
    return this.store.pipe(selectEntity(ediMsgHeadKey));
  }

  getAllEdiArchivesByMsgHeadId(ediMsgHeadKey: string): Observable<EdiMsgContentArchiveModel[] | undefined> {
    this.apiService.getAllEdiMsgContentArchivesByMsgHeadKey(ediMsgHeadKey).subscribe((items) => {
      this.store.update(upsertEntities(items as EdiMsgContentArchive[]));
    });
    return this.store
      .pipe(selectAllEntities());
  }
}

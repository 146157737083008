import { ErrorHandler, inject, Injectable, Injector, NgZone } from "@angular/core";
import { HttpErrorResponse } from "@angular/common/http";
import { ToastService, ToastType } from "./toast.service";

@Injectable()
export class GlobalErrorHandler extends ErrorHandler {
  private readonly injector = inject(Injector);
  private readonly zone = inject(NgZone);
  private toastService?: ToastService;

  override handleError(error: any) {
    if (!(error instanceof HttpErrorResponse)) {
      error = error.rejection;
    }
    this.zone.run(() => this.getToastService().showToast(ToastType.Error, "Application Error", error?.message));
  }

  /**
   * Getter for toastService because of a cyclic dependency
   */
  private getToastService(): ToastService {
    if (!this.toastService) {
      this.toastService = this.injector.get(ToastService);
    }
    return this.toastService;
  }
}

import { HttpErrorResponse } from "@angular/common/http";
import { AbstractControl, FormGroup, ValidationErrors } from "@angular/forms";
import { Observer } from "rxjs";

export interface Violation {
  field: string;
  message: string;
}

export interface FormControlErrorObject extends ValidationErrors {
  [message: string]: boolean;
}

// TODO Replace with Type from ApiClient when available.
export interface HttpErrorBody {
  type: string;
  title: string;
  status: number;
  violations: Violation[];
}

export interface BackNavigable {
  goBack(): void;
}

export function handleSave<T>(controller: BackNavigable, formGroup: FormGroup): Partial<Observer<T>> {
  return {
    complete: () => {
      formGroup.markAsPristine();
      controller.goBack();
    },
    error: (err: any) => {
      if (err instanceof HttpErrorResponse) {
        handleValidationErrors(err, formGroup);
      }
    }
  };
}

export function handleValidationErrors(response: HttpErrorResponse, form: FormGroup) {
  if (response.error && "violations" in response.error) {
    const violoations = (response.error as HttpErrorBody).violations;
    for (const violation of violoations) {
      validateFormGroup(form, violation);
    }
  }
}

/**
 * Put every violation into it's corresponding control of the form group.
 * If there is a form group inside the form group, start a recursion.
 */
function validateFormGroup(form: FormGroup, violation: Violation) {
  Object.keys(form.controls).forEach((controlName: string) => {
    ifApplicableStartRecursion(form.controls[controlName], violation);
    if (isControlMatchingViolation(violation, controlName)) {
      const controlErrorObject: FormControlErrorObject = createFormControlErrorObject(violation);
      form.controls[controlName].setErrors(controlErrorObject);
      form.setErrors(controlErrorObject);
    }
  });
}

function ifApplicableStartRecursion(control: AbstractControl, error: Violation) {
  if (control instanceof FormGroup) {
    return validateFormGroup(control, error);
  }
}

function isControlMatchingViolation(violation: Violation, controlName: string) {
  return violation.field === controlName;
}

function createFormControlErrorObject(violation: Violation) {
  return { [violation.message]: true };
}

<div *transloco="let t" class="w-full flex justify-between items-center">
  <div class="ml-12">
    <img src="assets/images/logo.png" alt="remainMyBox" class="-ml-8 mr-8" style="width: 124px;"/>
  </div>

  <div class="relative mt-2 mb-2" style="width: 500px;">
    <input type="text" placeholder="{{t('header.search.prompt')}}"
           class="border w-full border-gray-300 rounded-md px-3 py-2 pr-10 bg-blue-light"/>
    <button type="button"
            class="absolute right-2 top-1/2 transform -translate-y-1/2 bg-blue-500 hover:bg-blue-700 text-white font-bold py-1 px-2 rounded-md">
      <i class="ph ph-magnifying-glass text-blue font-bold text-lg/[32px]"></i>
    </button>
  </div>

  <div class="flex mr-2">
    <div class="flex items-center">
      <i class="ph ph-squares-four text-3xl text-blue"></i>
      <span class="text-blue ml-1">{{t('header.view')}}</span>
    </div>
    <div class="flex items-center mx-2">
      <span class="text-gray-light">|</span>
    </div>
    <div class="flex items-center mx-2">
      <i class="ph ph-user-circle text-3xl text-blue"></i>
      <span class="text-blue ml-1">{{t('header.profile')}}</span>
    </div>
  </div>
</div>

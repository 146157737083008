export enum JoinOperator {
  And = ";",
  Or = ","
}

export enum QueryOperator {
  Equals = "==",
  IgnoreCase = "=icase=",
  Like = "=like=",
  IgnoreCaseLike = "=ilike=",
  NotEqual = "!=",
  GreaterThan = "=gt=",
  LessThan = "=lt=",
  GreaterThanEqual = "=ge=",
  LessThanEqual = "=le",
}

/**
 * filter = "id=bt=(2,4)";// id>=2 && id<=4 //between
 * filter = "id=nb=(2,4)";// id<2 || id>4 //not between
 * filter = "company.code=like=em"; //like %em%
 * filter = "company.code=ilike=EM"; //ignore case like %EM%
 * filter = "company.code=icase=EM"; //ignore case equal EM
 * filter = "company.code=notlike=em"; //not like %em%
 * filter = "company.code=inotlike=EM"; //ignore case not like %EM%
 * filter = "company.code=ke=e*m"; //like %e*m%
 * filter = "company.code=ik=E*M"; //ignore case like %E*M%
 * filter = "company.code=nk=e*m"; //not like %e*m%
 * filter = "company.code=ni=E*M"; //ignore case not like %E*M%
 * filter = "company.code=ic=E^^M"; //ignore case equal E^^M
 * filter = "company.code==demo"; //equal
 * filter = "company.code=='demo'"; //equal
 * filter = "company.code==''"; //equal to empty string
 * filter = "company.code==dem*"; //like dem%
 * filter = "company.code==*emo"; //like %emo
 * filter = "company.code==*em*"; //like %em%
 * filter = "company.code==^EM"; //ignore case equal EM
 * filter = "company.code==^*EM*"; //ignore case like %EM%
 * filter = "company.code=='^*EM*'"; //ignore case like %EM%
 * filter = "company.code!=demo"; //not equal
 * filter = "company.code=in=(*)"; //equal to *
 * filter = "company.code=in=(^)"; //equal to ^
 * filter = "company.code=in=(demo,real)"; //in
 * filter = "company.code=out=(demo,real)"; //not in
 * filter = "company.id=gt=100"; //greater than
 * filter = "company.id=lt=100"; //less than
 * filter = "company.id=ge=100"; //greater than or equal
 * filter = "company.id=le=100"; //less than or equal
 * filter = "company.id>100"; //greater than
 * filter = "company.id<100"; //less than
 * filter = "company.id>=100"; //greater than or equal
 * filter = "company.id<=100"; //less than or equal
 * filter = "company.code=isnull=''"; //is null
 * filter = "company.code=null=''"; //is null
 * filter = "company.code=na=''"; //is null
 * filter = "company.code=nn=''"; //is not null
 * filter = "company.code=notnull=''"; //is not null
 * filter = "company.code=isnotnull=''"; //is not null
 *
 * filter = "company.code=='demo';company.id>100"; //and
 * filter = "company.code=='demo' and company.id>100"; //and
 *
 * filter = "company.code=='demo',company.id>100"; //or
 * filter = "company.code=='demo' or company.id>100"; //or
 */
export class RsqlBuilder {

  private join_operator: string;
  private str: string[] = [];

  constructor(join_op: JoinOperator = JoinOperator.And) {
    this.join_operator = join_op;
  }

  query(queryString: string | undefined,
        fields: string[],
        comparison: QueryOperator = QueryOperator.IgnoreCaseLike,
        joinMethod: JoinOperator = JoinOperator.Or): RsqlBuilder {
    if (queryString && queryString !== "") {
      this.str.push("(" + fields.map(f => f + comparison + "'" + queryString + "'").join(joinMethod) + ")");
    }
    return this;
  }

  eq(field: string, value: any) {
    return this.append(field, QueryOperator.Equals, value);
  }

  neq(field: string, value: any) {
    return this.append(field, QueryOperator.NotEqual, value);
  }

  like(field: string, value: any) {
    return this.append(field, QueryOperator.Like, value);
  }

  likeIgnoreCase(field: string, value: any) {
    return this.append(field, QueryOperator.IgnoreCaseLike, value);
  }

  gt(field: string, value: number) {
    return this.append(field, QueryOperator.GreaterThan, value);
  }

  gte(field: string, value: number) {
    return this.append(field, QueryOperator.GreaterThanEqual, value);
  }

  lt(field: string, value: number) {
    return this.append(field, QueryOperator.LessThan, value);
  }

  lte(field: string, value: number) {
    return this.append(field, QueryOperator.LessThanEqual, value);
  }


  private append(field: string, operator: QueryOperator, value: any) {
    if (value)
      this.str.push(field + operator + value);
    return this;
  }

  build(): string | undefined {
    if (this.str.length == 0) return undefined;
    return this.str.join(this.join_operator);
  }
}

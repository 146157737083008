import { Injectable } from "@angular/core";
import { createStore, withProps } from "@ngneat/elf";
import {
  addEntities,
  selectEntity,
  setActiveId,
  setEntities,
  upsertEntities,
  withActiveId,
  withEntities
} from "@ngneat/elf-entities";
import { DefaultService, SubcontractorModel, SubcontractorReferenceModel } from "@remainmybox/api";
import { Observable } from "rxjs";
import { UntilDestroy } from "@ngneat/until-destroy";
import { EntityRepository } from "../entity.repository";
import { Filter, Props } from "../filter-components";

export interface Subcontractor extends SubcontractorModel {
  subcontractorKey: string;
}


@UntilDestroy()
@Injectable({ providedIn: "root" })
export class SubcontractorRepository extends EntityRepository<Subcontractor> {
  protected defaultSearchProperties: string[] = [];

  public readonly subcontractors$ = this.all$;

  constructor(private readonly apiService: DefaultService) {
    super(
      createStore(
        { name: "subcontractor" },
        withProps<Props<Subcontractor>>({}),
        withEntities<Subcontractor, "subcontractorKey">({ idKey: "subcontractorKey" }),
        withActiveId()
      )
    );
  }

  public updateFilter(item: Filter) {
    this.store.update((state) => ({
      ...state,
      filter: item
    }));
    this.fetchSubcontractors();
  }

  private setSubcontractor(subcontractor: SubcontractorReferenceModel[]) {
    this.store.update(setEntities(subcontractor as Subcontractor[]));
    console.log(subcontractor);
  }

  public fetchSubcontractors() {
    this.apiService.searchSubcontractors().subscribe((result) => this.setSubcontractor(result));
  }

  public saveSubcontractor(id: string | undefined, model: any) {
    if (id) {
      model.subcontractorKey = id;
      this.getAuditProperties(id, model).subscribe(completeModel => {
        model = completeModel;
      });
    }
    return this.apiService.saveSubcontractor(model).pipe(this.mapUpsert());
  }

  public createSubcontractor(): void {
    this.store.update(addEntities([{ subcontractorKey: "" } as Subcontractor]), setActiveId(""));
  }

  getById(subcontractorKey: string): Observable<SubcontractorModel | undefined> {
    this.apiService
      .getSubcontractorByKey(subcontractorKey)
      .subscribe((item) => this.store.update(upsertEntities(item as Subcontractor)));
    return this.store.pipe(selectEntity(subcontractorKey));
  }
}

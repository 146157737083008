import { ErrorHandler, NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { StylesModule } from "@remainmybox/ui/styles";
import { GlobalErrorHandler } from "./services/global-error-handler";

@NgModule({
  imports: [CommonModule, StylesModule],
  providers: [{ provide: ErrorHandler, useClass: GlobalErrorHandler }]
})
export class SharedServicesModule {}

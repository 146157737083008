import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { RouterModule } from '@angular/router';
import { NavLinkComponent } from "./nav-link/nav-link.component";
import { TranslocoModule } from "@ngneat/transloco";

@NgModule({
  imports: [CommonModule, RouterModule, TranslocoModule],
  declarations: [NavLinkComponent],
  exports: [NavLinkComponent],
})
export class UiNavLinkModule {}

import { Injectable } from "@angular/core";
import { createStore, withProps } from "@ngneat/elf";
import { selectEntity, withActiveId, withEntities } from "@ngneat/elf-entities";
import { DefaultService, CedexModel } from "@remainmybox/api";
import { Observable } from "rxjs";
import { UntilDestroy } from "@ngneat/until-destroy";
import { EntityRepository } from "../entity.repository";
import { Filter, Props } from "../filter-components";

export interface Cedex extends CedexModel {
  cedexKey: string;
}

@UntilDestroy()
@Injectable({ providedIn: "root" })
export class CedexRepository extends EntityRepository<Cedex> {
  protected defaultSearchProperties: string[] = ["cd", "name", "nameDe"];

  constructor(private readonly apiService: DefaultService) {
    super(
      createStore(
        { name: "cedexs" },
        withProps<Props<CedexModel>>({}),
        withEntities<Cedex, "cedexKey">({ idKey: "cedexKey" }),
        withActiveId()
      )
    );
  }

  public updateFilter(item: Filter) {
    this.store.update((state) => ({
      ...state,
      filter: item
    }));
    this.fetchCedexs();
  }

  public fetchCedexs() {
    const filter = this.store.getValue().filter;
    this.apiService
      .searchCedex(this.rsql(filter).build())
      .pipe(this.trackRequest())
      .subscribe((cedexs) => this.set(cedexs as Cedex[]));
  }

  public getByKey(key: string): Observable<CedexModel | undefined> {
    this.apiService.getCedexByKey(key).subscribe((cedex) => this.upsert(cedex));
    return this.store.pipe(selectEntity(key));
  }

  public saveCedex(key: string | undefined, model: CedexModel) {
    if (key) {
      model.cedexKey = key;
      this.getAuditProperties(key, model as Cedex).subscribe(completeModel => {
        model = completeModel;
      });
    }
    return this.apiService.saveCedex(model).pipe(this.mapUpsert());
  }
}

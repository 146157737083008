import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { ErrorIconComponent } from "./error.component";
import { SuccessIconComponent } from "./success.component";
import { InfoIconComponent } from "./info.component";
import { DialogModule } from "@angular/cdk/dialog";
import { TranslocoModule } from "@ngneat/transloco";

@NgModule({
  imports: [CommonModule, DialogModule, TranslocoModule],
  declarations: [ErrorIconComponent, SuccessIconComponent, InfoIconComponent],
  exports: [ErrorIconComponent, SuccessIconComponent, InfoIconComponent]
})
export class UiPopupModule {}

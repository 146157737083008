export const environment = {
  production: undefined,
  apiBasePath: '',
  aws: {
    gateway: 'undefined',
    protocol: 'undefined',
    region: 'eu-central-1',
    identityPoolId: '',
    userPoolId: '',
    userPoolWebClientId: '',
    defaultBucket: ''
  },
  localDatabase: '',
  localDatabaseAddress: ''
};

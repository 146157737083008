import { UntilDestroy } from "@ngneat/until-destroy";
import { Injectable } from "@angular/core";
import { DefaultService, EdiMsgConfigModel } from "@remainmybox/api";
import { createStore, withProps } from "@ngneat/elf";
import {
  selectAllEntities,
  selectEntity,
  setEntities,
  upsertEntities,
  withActiveId,
  withEntities
} from "@ngneat/elf-entities";
import { Observable } from "rxjs";
import { EntityRepository } from "../../entity.repository";
import { Filter, Props } from "../../filter-components";

export interface EdiMsgConfig extends EdiMsgConfigModel {
  ediMsgConfigKey: string;
}

@UntilDestroy()
@Injectable({ providedIn: "root" })
export class EdiMsgConfigRepository extends EntityRepository<EdiMsgConfig> {
  protected defaultSearchProperties: string[] = [];
  public readonly ediMsgConfigs$: Observable<EdiMsgConfig[]> = this.store.pipe(selectAllEntities());

  constructor(private readonly apiService: DefaultService) {
    super(
      createStore(
        { name: "ediMsgConfigs" },
        withProps<Props<EdiMsgConfigModel>>({}),
        withEntities<EdiMsgConfig, "ediMsgConfigKey">({ idKey: "ediMsgConfigKey" }),
        withActiveId()
      )
    );
  }

  public updateFilter(item: Filter) {
    this.store.update((state) => ({
      ...state,
      filter: item
    }));
    this.fetchEdiMsgConfigs();
  }

  private fetchEdiMsgConfigs() {
    //There is no api for this
    // this.apiService.searchEdiMsgRefs().subscribe((result) => this.setEdiMsgConfigs(result));
  }

  private setEdiMsgConfigs(ediMsgConfigs: EdiMsgConfig[]) {
    this.store.update(setEntities(ediMsgConfigs as EdiMsgConfig[]));
  }

  // getAllByMsgHeadId(ediMsgHeadKey: string): Observable<EdiMsgRefModel[] | undefined> {
  //   this.apiService.getAllEdiMsgRefsByMsgHeadKey(ediMsgHeadKey).subscribe((items) => {
  //     this.store.update(upsertEntities(items as EdiMsgConfig[]));
  //   });
  //   return this.store
  //     .pipe(selectAllEntities());
  // }

  getById(ediMsgConfigKey: string): Observable<EdiMsgConfig | undefined> {
    //there is no api for this getmsgref -> msgconfig
    this.apiService.getEdiMsgRefByKey(ediMsgConfigKey).subscribe((item) => {
      this.store.update(upsertEntities(item as EdiMsgConfig));
    });
    return this.store.pipe(selectEntity(ediMsgConfigKey));
  }
}

import { Injectable } from "@angular/core";
import { createStore, withProps } from "@ngneat/elf";
import { selectEntity, setEntities, upsertEntities, withActiveId, withEntities } from "@ngneat/elf-entities";
import { DefaultService, CtrSizetypeModel } from "@remainmybox/api";
import { Observable } from "rxjs";
import { UntilDestroy } from "@ngneat/until-destroy";
import { EntityRepository } from "../entity.repository";
import { Filter, Props } from "../filter-components";

export interface CtrSizetype extends CtrSizetypeModel {
  ctrSizetypeKey: string;
}


@UntilDestroy()
@Injectable({providedIn: "root"})
export class CtrSizetypeRepository extends EntityRepository<CtrSizetype> {
  protected defaultSearchProperties: string[] = ["isoCd", "typeCd", "remark"];

  constructor(private readonly apiService: DefaultService) {
    super(
      createStore(
        {name: "ctrsizes"},
        withProps<Props<CtrSizetypeModel>>({}),
        withEntities<CtrSizetype, "ctrSizetypeKey">({idKey: "ctrSizetypeKey"}),
        withActiveId()
      )
    );
  }

  public updateFilter(item: Filter) {
    this.store.update((state) => ({
      ...state,
      filter: item
    }));
    this.fetchCtrSizeTypes();
  }

  private setCtrSizeTypes(ctrSizeTypes: CtrSizetypeModel[]) {
    this.store.update(setEntities(ctrSizeTypes as CtrSizetype[]));
  }

  public fetchCtrSizeTypes() {
    const filter = this.store.getValue().filter;
    this.apiService
      .searchCtrSizetypes(this.rsql(filter).build())
      .pipe(this.trackRequest())
      .subscribe((result) => this.setCtrSizeTypes(result));
  }

  public getByKey(key: string): Observable<CtrSizetypeModel | undefined> {
    this.apiService
      .getCtrSizetypeByKey(key)
      .subscribe((item) => this.store.update(upsertEntities(item as CtrSizetype)));
    return this.store.pipe(selectEntity(key));
  }

  public saveCtrSizetype(key: string | undefined, model: CtrSizetypeModel) {
    if (key) {
      model.ctrSizetypeKey = key;
      this.getAuditProperties(key, model as CtrSizetype).subscribe(completeModel => {
        model = completeModel;
      });
    }
    return this.apiService.saveCtrSizetype(model).pipe(this.mapUpsert());
  }
}

import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  Optional,
  Output,
  ViewChild
} from "@angular/core";
import {
  ControlContainer,
  ControlValueAccessor,
  FormControl,
  FormControlDirective,
  ValidationErrors
} from "@angular/forms";
import {UntilDestroy, untilDestroyed} from "@ngneat/until-destroy";

@UntilDestroy()
@Component({ template: "" })
export abstract class AbstractValueAccessorComponent<T = unknown> implements ControlValueAccessor, AfterViewInit {
  @ViewChild(FormControlDirective, { static: true })
  formControlDirective?: FormControlDirective;
  private _status?: string;

  @Input() public required = false;
  @Input() type?: string;

  @Input() formControl?: FormControl;
  @Input() formControlName = "";
  @Input() disabled = false;
  @Input() value?: T;

  @Input() name?: string;
  @Input() placeholder = " ";
  @Input() readonly = false;
  @Output() changed: EventEmitter<T> = new EventEmitter<T>();
  @Output() blurred: EventEmitter<void> = new EventEmitter<void>();
  @Input() error?: ValidationErrors | string;
  showErrorIcon = false;
  @Input() checked: any;
  @Input() formControlChild = "";

  @Input() label = "";

  /* eslint-disable */
  onChange: (value?: T) => void = (_?: T) => {};
  onTouched: () => void = () => {};

  /* eslint-enable */

  get control() {
    return this.formControl || this.controlContainer?.control?.get(this.formControlName);
  }

  get controlContainer() {
    return this._controlContainer;
  }

  constructor(
    protected changeDetectorRef: ChangeDetectorRef,
    @Optional() protected _controlContainer: ControlContainer
  ) {}

  ngAfterViewInit(): void {
    if (this.control) {
      this.control.statusChanges.pipe(untilDestroyed(this)).subscribe({
        next: () => {
          this.displayErrors();
          this.changeDetectorRef.markForCheck();
        }
      });
    }
  }

  /**
   * Registers change listener for custom form control
   */
  registerOnChange(onChange: (_?: T) => void): void {
    this.onChange = onChange;
  }

  /**
   * Registers change listener for custom form control
   */
  registerOnTouched(onTouch: () => void): void {
    this.onTouched = onTouch;
  }

  /**
   * Sets the disabled state
   */
  setDisabledState(isDisabled: boolean): void {
    this.disabled = isDisabled;
  }

  /**
   * This is called whenever form control is updated
   * Will be called when someone will call -> form.patchValue({control: 'value'}) or form.setValue({control: 'value'})
   */
  writeValue(value: T): void {
    if (this.formControlChild != "") {
      this.value = <T>value[this.formControlChild as keyof T];
    } else {
      this.value = value;
    }
    this.changeDetectorRef.markForCheck();
  }

  /**
   * Marks the field as touched as soon as focus is lost
   */
  markInputAsTouched(): void {
    this.onTouched();
    this.displayErrors();
    this.blurred.emit();
  }

  displayErrors(): void {
    if (!this.control) {
      return;
    }

    if (this.control.errors) {
      if (Object.keys(this.control.errors).includes("required")) {
        return;
      } else {
        this.error = this.convertedErrors(this.control.errors);
      }
    }
  }

  /**
   * Translates the angular validator keys
   */
  private convertedErrors(errors: ValidationErrors) {
    Object.keys(errors)
      .filter((key) => ANGULAR_VALIDATORS.includes(key))
      .forEach((key) => {
        errors[`validation.${key}`] = errors[key];
        delete errors[key];
      });
    return errors;
  }
}

const ANGULAR_VALIDATORS = [
  "email",
  "max",
  "maxLength",
  "min",
  "minLength",
  "nullValidator",
  "pattern",
  "required",
  "requiredTrue"
];

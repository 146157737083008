<!--<div class="relative h-full">-->
<lib-header></lib-header>
<div class="flex h-screen bg-blue-light overflow-auto">
  <lib-ui-sidebar [menuItems]="menuItems" ></lib-ui-sidebar>
  <div class="flex flex-col h-full flex-grow -ml-4">
    <main class="flex flex-col grow h-full overflow-auto">
      <router-outlet></router-outlet>
    </main>
  </div>
</div>

import { Directive, HostListener, Input } from '@angular/core';

@Directive({
  selector: '[appCtrlClick]'
})
export class CtrlClickDirective {
  @Input() href?: string;

  @HostListener('click', ['$event'])
  onClick(event: MouseEvent): void {
    if (event.ctrlKey) {
      event.preventDefault();
      if (this.href) {
        window.open(this.href, '_blank');
      }
    }
  }
}

import { NgModule } from "@angular/core";
import {CommonModule, NgOptimizedImage} from "@angular/common";
import { HeaderComponent } from "./header/header.component";
import { AngularSvgIconModule } from "angular-svg-icon";
import { CdkMenuModule } from "@angular/cdk/menu";
import { TranslocoModule } from "@ngneat/transloco";
import { RouterLink, RouterLinkActive, RouterModule } from "@angular/router";
import { UiNavLinkModule } from "@remainmybox/ui/nav-link";
import { UiFormElementsModule } from "@remainmybox/ui/form-elements";

@NgModule({
  imports: [CommonModule, AngularSvgIconModule, CdkMenuModule, TranslocoModule, RouterLink, RouterLinkActive, RouterModule, UiNavLinkModule, UiFormElementsModule, NgOptimizedImage],
  declarations: [HeaderComponent],
  exports: [HeaderComponent]
})
export class UiHeaderModule {}

import {ChangeDetectorRef, Component, ElementRef, HostListener, Input, Optional, ViewChild} from "@angular/core";
import { ControlContainer } from "@angular/forms";
import { AbstractValueAccessorComponent } from "../abstract-value-accessor.component";
import { ValueAccessorUtil } from "../value-accessor.util";

export type InputSizeType = "small" | "medium" | "large";
enum InputSize {
  small = 'small',
  medium = 'medium',
  large = 'large'
}

@Component({
  selector: "remainmybox-form-textarea",
  templateUrl: "./form-textarea.component.html",
  styleUrls: ["./form-textarea.component.scss"],
  providers: [...ValueAccessorUtil.getValueAccessorProvider(FormTextareaComponent)]
})
export class FormTextareaComponent extends AbstractValueAccessorComponent<string> {

  @Input() public resizeNone = false;
  @Input() public inputSize: InputSizeType = "large";
  @Input() public hasCopyIcon = false;
  @Input() public hasPencilIcon = false;

  private baseIconClasses = 'flex items-center justify-center absolute mr-2 top-[12px] transform -translate-y-1/2';

  constructor(
    protected override changeDetectorRef: ChangeDetectorRef,
    @Optional() protected override _controlContainer: ControlContainer
  ) {
    super(changeDetectorRef, _controlContainer);
  }

  inputChanged(): void {
    this.onChange(this.value);
    this.formControl?.setValue(this.value);
    this.changed.emit(this.value);
  }

  onKeyup(event: any) {
    this.onChange(event.target.value);
    this.formControl?.setValue(event.target.value);
    this.changed.emit(event.target.value);
  }

  get inputClasses(): string {
    let classes = 'mt-0.5 bg-white caret-blue focus:outline-blue focus:outline-0 disabled:outline-dashed disabled:outline-1 disabled:border-0 disabled:outline-gray-light border focus:border-blue text-black font-text-m-semibold text-sm rounded pb-0.5 block dark:bg-gray dark:border-gray dark:placeholder-gray dark:text-white dark:focus:ring-blue dark:focus:border-blue pl-3 ';
    classes = this.handleRequired(classes);
    classes = this.handleInputSize(classes);
    classes = this.handleErrors(classes);
    return classes;
  }

  get errorIconClasses(): string {
    let classes = `${this.baseIconClasses} ph ph-exclamation-mark text-white bg-red w-4 h-4 rounded-full`;
    classes = this.handleIconPositions(classes);
    return classes;
  }

  get copyIconClasses(): string {
    let classes = `${this.baseIconClasses} ph ph-copy-simple text-blue text-xl bg-transparent font-normal`;
    classes = this.handleIconPositions(classes);
    return classes;
  }

  get pencilIconClasses(): string {
    let classes = `${this.baseIconClasses} ph ph-pencil text-blue text-xl bg-transparent font-normal`;
    classes = this.handleIconPositions(classes);
    return classes;
  }

  private handleRequired(classes: string): string {
    if (this.required) {
      classes += ' bg-yellow-light border-yellow';
    } else {
      classes += ' border-gray-light';
    }
    return classes;
  }

  private handleInputSize(classes: string): string {
    if (this.inputSize === InputSize.small) {
      classes += ' w-1/3';
    } else if (this.inputSize === InputSize.medium) {
      classes += ' w-2/3';
    } else if (this.inputSize === InputSize.large) {
      classes += ' w-full';
    }
    return classes;
  }

  private handleIconPositions(classes: string): string {
    if ((this.hasCopyIcon || this.hasPencilIcon) && this.control?.errors && this.control.touched) {
      classes = this.handleIconPositionsIfAllIconsPresent(classes);
    }
    if (this.inputSize === InputSize.small) {
      classes += ' right-2/3';
    } else if (this.inputSize === InputSize.medium) {
      classes += ' right-1/3';
    } else if (this.inputSize === InputSize.large) {
      classes += ' right-0';
    }
    return classes;
  }

  private handleIconPositionsIfAllIconsPresent(classes: string): string {
    if (classes.includes('ph-copy-simple') || classes.includes('ph-pencil')) {
      classes += ' right-0';
    } else if (classes.includes('ph-exclamation-mark')) {
      if (this.inputSize === InputSize.small) {
        classes += ' left-24';
      } else if (this.inputSize === InputSize.medium) {
        classes += ' right-40 mr-4';
      } else if (this.inputSize === InputSize.large) {
        classes += ' right-6';
      }
    }
    return classes;
  }

  private handleErrors(classes: string): string {
    if (this.control?.errors && this.control.touched) {
      if (classes.includes('border-yellow')) {
        classes = classes.replace('border-yellow', 'border-red');
      } else {
        classes += ' border-red';
      }
    }
    return classes;
  }
}

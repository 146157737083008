import { Injectable } from "@angular/core";
import { createStore, withProps } from "@ngneat/elf";
import { selectEntity, withActiveId, withEntities } from "@ngneat/elf-entities";
import { DefaultService, QualityModel } from "@remainmybox/api";
import { Observable } from "rxjs";
import { UntilDestroy } from "@ngneat/until-destroy";
import { EntityRepository } from "../entity.repository";
import { Filter, Props } from "../filter-components";

export interface Quality extends QualityModel {
  qualityKey: string;
}

@UntilDestroy()
@Injectable({ providedIn: "root" })
export class QualityRepository extends EntityRepository<Quality> {
  protected defaultSearchProperties: string[] = ["qualityId", "text"];

  constructor(private readonly apiService: DefaultService) {
    super(
      createStore(
        { name: "qualities" },
        withProps<Props<QualityModel>>({}),
        withEntities<Quality, "qualityKey">({ idKey: "qualityKey" }),
        withActiveId()
      )
    );
  }

  public updateFilter(item: Filter) {
    this.store.update((state) => ({
      ...state,
      filter: item
    }));
    this.fetchQualities();
  }

  public fetchQualities() {
    const filter = this.store.getValue().filter;
    this.apiService
      .searchQualities(this.rsql(filter).build())
      .pipe(this.trackRequest())
      .subscribe((qualities) => this.set(qualities as Quality[]));
  }

  public getByKey(key: string): Observable<QualityModel | undefined> {
    this.apiService.getQualityByKey(key).subscribe((quality) => this.upsert(quality));
    return this.store.pipe(selectEntity(key));
  }

  public saveQuality(key: string | undefined, model: QualityModel) {
    if (key) {
      model.qualityKey = key;
      this.getAuditProperties(key, model as Quality).subscribe(completeModel => {
        model = completeModel;
      });
    }
    return this.apiService.saveQuality(model).pipe(this.mapUpsert());
  }
}

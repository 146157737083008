import { CountryModel, DefaultService } from "@remainmybox/api";
import { Injectable } from "@angular/core";
import { createStore, withProps } from "@ngneat/elf";
import { withActiveId, withEntities } from "@ngneat/elf-entities";
import { EntityRepository } from "../entity.repository";
import { Props } from "../filter-components";

export interface Country extends CountryModel {
  countryKey: string;
}

@Injectable({ providedIn: "root" })
export class CountryRepository extends EntityRepository<Country> {
  protected defaultSearchProperties: string[] = [];

  constructor(private readonly apiService: DefaultService) {
    super(
      createStore(
        { name: "countries" },
        withProps<Props<CountryModel>>({}),
        withEntities<Country, "countryKey">({ idKey: "countryKey" }),
        withActiveId()
      )
    );
  }

  public fetchCountries() {
    this.apiService.searchCountries().subscribe((result) => this.set(result as Country[]));
  }
}

import { UntilDestroy } from "@ngneat/until-destroy";
import { Injectable } from "@angular/core";
import { DefaultService, EdiMsgRefModel } from "@remainmybox/api";
import { createStore, withProps } from "@ngneat/elf";
import {
  selectAllEntities,
  selectEntity,
  setEntities,
  upsertEntities,
  withActiveId,
  withEntities
} from "@ngneat/elf-entities";
import { Observable } from "rxjs";
import { EntityRepository } from "../../entity.repository";
import { Filter, Props } from "../../filter-components";

export interface EdiMsgRef extends EdiMsgRefModel {
  ediMsgRefKey: string,
}

@UntilDestroy()
@Injectable({ providedIn: "root" })
export class EdiMsgReferenceRepository extends EntityRepository<EdiMsgRef> {
  protected defaultSearchProperties: string[] = [];
  public readonly ediMessageRefs$: Observable<EdiMsgRef[]> = this.store.pipe(selectAllEntities());

  constructor(private readonly apiService: DefaultService) {
    super(
      createStore(
        { name: "ediMessageRefs" },
        withProps<Props<EdiMsgRefModel>>({}),
        withEntities<EdiMsgRef, "ediMsgRefKey">({ idKey: "ediMsgRefKey" }),
        withActiveId()
      )
    );
  }

  public updateFilter(item: Filter) {
    this.store.update(
      (state) => ({
        ...state,
        filter: item
      }));
    this.fetchEdiMsgRefs();
  }

  private fetchEdiMsgRefs() {
    this.apiService.searchEdiMsgRefs().subscribe(result => this.setEdiMessageRefs(result));
  }

  private setEdiMessageRefs(ediMsgRefs: EdiMsgRefModel[]) {
    this.store.update(
      setEntities(ediMsgRefs as EdiMsgRef[])
    );
  }

  getAllByMsgHeadId(ediMsgHeadKey: string): Observable<EdiMsgRefModel[] | undefined> {
    this.apiService.getAllEdiMsgRefsByMsgHeadKey(ediMsgHeadKey).subscribe((items) => {
      this.store.update(upsertEntities(items as EdiMsgRef[]));
    });
    return this.store
      .pipe(selectAllEntities());
  }

  getByKey(ediMsgRefKey: string): Observable<EdiMsgRefModel | undefined> {
    this.apiService.getEdiMsgRefByKey(ediMsgRefKey).subscribe((item) => {
      this.store.update(upsertEntities(item as EdiMsgRef));
    });
    return this.store.pipe(selectEntity(ediMsgRefKey));
  }

}

<div [ngClass]="outerDivClasses" *transloco="let t">
  <div [ngClass]="{ 'flex': label, 'flex-row': label }">
    <input
      class="w-4 h-4 rounded mb-[2px]"
      type="checkbox"
      [id]="name || formControlName"
      (change)="inputChanged()"
      (blur)="markInputAsTouched()"
      [disabled]="disabled"
      [checked]="checked"
      [(ngModel)]="value"
    >
    <ng-container *ngIf="label">
      <label class="w-full pl-[3px] bg-none font-text-m-regular" [for]="name || formControlName">
        {{ t(label) }}
      </label>
    </ng-container>
  </div>
  <remainmybox-form-error
    [error]="error" *ngIf="control?.touched && control?.errors && error">
  </remainmybox-form-error>
</div>

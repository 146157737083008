import { Injectable } from "@angular/core";
import { createStore, withProps } from "@ngneat/elf";
import { selectEntity, withActiveId, withEntities } from "@ngneat/elf-entities";
import { DefaultService, FacilityModel } from "@remainmybox/api";
import { Observable } from "rxjs";
import { UntilDestroy } from "@ngneat/until-destroy";
import { EntityRepository } from "../entity.repository";
import { Filter, Props } from "../filter-components";

export interface Facility extends FacilityModel {
  facilityKey: string;
}

@UntilDestroy()
@Injectable({ providedIn: "root" })
export class FacilityRepository extends EntityRepository<Facility> {
  protected defaultSearchProperties: string[] = ["facilityId", "name"];

  constructor(private readonly apiService: DefaultService) {
    super(
      createStore(
        { name: "facilities" },
        withProps<Props<FacilityModel>>({}),
        withEntities<Facility, "facilityKey">({ idKey: "facilityKey" }),
        withActiveId()
      )
    );
  }

  public updateFilter(item: Filter) {
    this.store.update((state) => ({
      ...state,
      filter: item
    }));
    this.fetchFacilities();
  }

  public fetchFacilities() {
    const filter = this.store.getValue().filter;
    this.apiService
      .searchFacilities(this.rsql(filter).build())
      .pipe(this.trackRequest())
      .subscribe((facilities) => this.set(facilities as Facility[]));
  }

  public getByKey(key: string): Observable<FacilityModel | undefined> {
    this.apiService.getFacilityByKey(key).subscribe((facility) => this.upsert(facility));
    return this.store.pipe(selectEntity(key));
  }

  public saveFacility(key: string | undefined, model: FacilityModel) {
    if (key) {
      model.facilityKey = key;
      this.getAuditProperties(key, model as Facility).subscribe(completeModel => {
        model = completeModel;
      });
    }
    return this.apiService.saveFacility(model).pipe(this.mapUpsert());
  }
}

import {Component, inject, Input} from "@angular/core";
import {ValidationErrors} from "@angular/forms";
import {TranslocoService} from "@ngneat/transloco";

@Component({
  selector: "remainmybox-form-error",
  templateUrl: "./form-error.component.html",
  styleUrls: ["./form-error.component.scss"]
})
export class FormErrorComponent {
  private readonly transloco = inject(TranslocoService)

  @Input() error?: ValidationErrors | string;

  getErrors(): ValidationErrors {
    if (!this.error) return {};

    if (typeof this.error === "string") {
      return {[this.error]: {}}
    }

    return this.error;
  }

  getText(): string {
    const result: string[] = []
    for (const [key, params] of Object.entries(this.getErrors())) {
      result.push(this.transloco.translate(key, params))
    }
    return result.join(', ')
  }
}

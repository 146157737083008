import { Injectable } from "@angular/core";
import { createStore, getRegistry, select, withProps } from "@ngneat/elf";
import { KeycloakService } from "keycloak-angular";
import { TranslocoService } from "@ngneat/transloco";
import { KeycloakProfile } from "keycloak-js";
import { Router } from "@angular/router";

interface AuthProps {
  user: KeycloakProfile | null;
  roles: string[] | null;
  language: string;
}

const authStore = createStore({ name: "auth" }, withProps<AuthProps>({ user: null, language: "de", roles: null }));

@Injectable({ providedIn: "root" })
export class UserService {
  user$ = authStore.pipe(select((state) => state.user));

  constructor(
    private keycloakService: KeycloakService,
    private translocoService: TranslocoService,
    private router: Router
  ) {
    this.keycloakService
      .isLoggedIn()
      .then((loggedIn) => {
        if (loggedIn) {
          this.keycloakService
            .loadUserProfile()
            .then((profile) => {
              this.updateUser(profile, this.keycloakService.getUserRoles());
            })
            .catch((reason) => {
              console.log(reason);
            });
        }
      })
      .catch((reason) => console.log(reason));
  }

  updateUser(user: AuthProps["user"], roles: string[]) {
    authStore.update((state) => ({
      ...state,
      user,
      roles
    }));
  }

  async logout(): Promise<void> {
    await this.keycloakService.logout();
    // clear stores
    getRegistry().forEach((store) => store.reset());
  }

  updateLanguage(language: string): void {
    this.translocoService.setActiveLang(language);

    authStore.update((state) => ({
      ...state,
      language
    }));
  }

  manageAccount(): void {
    window.open(
      [
        this.keycloakService.getKeycloakInstance().authServerUrl,
        "admin",
        this.keycloakService.getKeycloakInstance().realm,
        "console"
      ].join("/"),
      "_blank"
    );
  }

  public getUserName() {
    return this.keycloakService.getKeycloakInstance().profile?.username;
  }
}

import { Injectable } from "@angular/core";
import { createStore, withProps } from "@ngneat/elf";
import {
  addEntities,
  selectActiveEntity,
  selectAllEntities,
  setActiveId,
  setEntities,
  upsertEntities,
  withActiveId,
  withEntities
} from "@ngneat/elf-entities";

import { CustomerEstimatePositionModel, DefaultService } from "@remainmybox/api";
import { Observable } from "rxjs";
import { UntilDestroy } from "@ngneat/until-destroy";
import { EntityRepository } from "../../entity.repository";
import { Props } from "../../filter-components";

export interface CustomerEstimatePosition extends CustomerEstimatePositionModel {
  customerEstimatePositionKey: string;
}


@UntilDestroy()
@Injectable({ providedIn: "root" })
export class CustomerEstimatePositionRepository extends EntityRepository<CustomerEstimatePosition> {
  protected defaultSearchProperties: string[] = [];


  public readonly activeCustomerEstimatePosition$: Observable<CustomerEstimatePositionModel | undefined> =
    this.store.pipe(selectActiveEntity());

  public readonly customerEstimatePositions$: Observable<CustomerEstimatePositionModel[]> = this.store.pipe(
    selectAllEntities()
  );

  constructor(private readonly apiService: DefaultService) {
    super(
      createStore(
        { name: "customers" },
        withProps<Props<CustomerEstimatePositionModel>>({}),
        withEntities<CustomerEstimatePosition, "customerEstimatePositionKey">({ idKey: "customerEstimatePositionKey" }),
        withActiveId()
      )
    );
  }

  private setCustomerEstimatePositions(customers: CustomerEstimatePositionModel[]) {
    this.store.update(setEntities(customers as CustomerEstimatePosition[]));
  }

  public fetchCustomerEstimatePositions() {
    this.apiService.searchCustomerEstimatePositions().subscribe((result) => this.setCustomerEstimatePositions(result));
    console.log("customers", this.customerEstimatePositions$);
  }

  public updateFilter(item: any) {
    this.store.update((state) => ({
      ...state,
      filter: item
    }));
  }

  public saveCustomerEstimatePosition(key: string | undefined, model: CustomerEstimatePositionModel) {
    if (key) {
      model.customerEstimatePositionKey = key;
      this.getAuditProperties(key, model as CustomerEstimatePosition).subscribe(completeModel => {
        model = completeModel;
      });
    }

    this.apiService.saveCustomerEstimatePosition(model).subscribe((saved: CustomerEstimatePositionModel) => {
      this.store.update(upsertEntities(saved as CustomerEstimatePosition));
      if (saved.customerEstimatePositionKey)
        this.setActive(saved.customerEstimatePositionKey);
    });
  }

  public createCustomerEstimatePosition(): void {
    this.store.update(addEntities([{} as CustomerEstimatePosition]), setActiveId(""));
  }
}

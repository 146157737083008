import { UntilDestroy } from "@ngneat/until-destroy";
import { Injectable } from "@angular/core";
import { createStore, withProps } from "@ngneat/elf";
import {
  selectActiveEntity,
  selectAllEntities,
  selectEntity,
  setEntities,
  upsertEntities,
  withActiveId,
  withEntities
} from "@ngneat/elf-entities";
import { Observable } from "rxjs";
import {
  CustomerModel,
  DefaultService,
  EdiMsgGroupTypeModel,
  EdiMsgHeadModel,
  EdiMsgProcStatusTypeModel
} from "@remainmybox/api";
import { RsqlBuilder } from "../../rsql-builder";
import { Filter, Props } from "../../filter-components";
import { EntityRepository } from "../../entity.repository";

export interface EdiMessageHead extends EdiMsgHeadModel {
  ediMsgHeadKey: string;
}

export interface EdiMessageHeadFilter extends Filter {
  customer?: CustomerModel;
  messageGroup?: EdiMsgGroupTypeModel;
  processStatus?: EdiMsgProcStatusTypeModel;
}

export interface EdiMessageHeadProps extends Props<EdiMessageHead> {
  filter?: EdiMessageHeadFilter;
}

@UntilDestroy()
@Injectable({ providedIn: "root" })
export class EdiMsgHeadRepository extends EntityRepository<EdiMessageHead> {
  protected defaultSearchProperties: string[] = ["procremark", "ediMsgRefs.refId"];
  public readonly ediMessageHeads$: Observable<EdiMessageHead[]> = this.store.pipe(selectAllEntities());
  public ediMessageGroups: string[] = [];
  public ediMsgChildDetails$: Observable<EdiMessageHead | undefined> = this.store.pipe(selectActiveEntity());

  constructor(private readonly apiService: DefaultService) {
    super(
      createStore(
        { name: "ediMessageHeads" },
        withProps<EdiMessageHeadProps>({}),
        withEntities<EdiMessageHead, "ediMsgHeadKey">({ idKey: "ediMsgHeadKey" }),
        withActiveId()
      )
    );
  }

  public updateFilter(item: EdiMessageHeadFilter) {
    this.store.update((state) => ({
      ...state,
      filter: item
    }));
    this.fetchEdiMessageHeads();
  }

  private setEdiMessageHeads(ediMessageHeads: EdiMsgHeadModel[]) {
    this.store.update(setEntities(ediMessageHeads as EdiMessageHead[]));
  }

  public fetchEdiMessageHeads() {
    const filter = this.store.getValue().filter;
    this.apiService
      .searchEdiMsgHeads(this.rsql(filter).build())
      .subscribe((result) => this.setEdiMessageHeads(result));
  }

  public fetchEdiMsgGroups() {
    this.apiService.getAllEdiMsgGroups("body").subscribe((result) => {
      this.ediMessageGroups = result;
    });
    return this.ediMessageGroups;
  }

  getByKey(ediMsgHeadKey: string): Observable<EdiMessageHead | undefined> {
    this.apiService.getEdiMsgHeadByKey(ediMsgHeadKey).subscribe((item) => {
      this.store.update(upsertEntities(item as EdiMessageHead));
    });
    return this.store.pipe(selectEntity(ediMsgHeadKey));
  }

  override rsql(filter: EdiMessageHeadFilter | undefined): RsqlBuilder {
    return super.rsql(filter)
      .eq("procstatus", filter?.processStatus)
      .eq("ediMsgType.group", filter?.messageGroup)
      .eq("customer.customerKey", filter?.customer?.customerKey);
  }
}

import { Injectable } from "@angular/core";
import { createStore, withProps } from "@ngneat/elf";
import { selectEntity, withActiveId, withEntities } from "@ngneat/elf-entities";
import { DefaultService, CheckConfigModel } from "@remainmybox/api";
import { Observable } from "rxjs";
import { UntilDestroy } from "@ngneat/until-destroy";
import { EntityRepository } from "../entity.repository";
import { Filter, Props } from "../filter-components";

export interface CheckConfig extends CheckConfigModel {
  checkConfigKey: string;
}

@UntilDestroy()
@Injectable({ providedIn: "root" })
export class CheckConfigRepository extends EntityRepository<CheckConfig> {
  protected defaultSearchProperties: string[] = ["checkConfigId", "text"];

  constructor(private readonly apiService: DefaultService) {
    super(
      createStore(
        { name: "checkConfigs" },
        withProps<Props<CheckConfigModel>>({}),
        withEntities<CheckConfig, "checkConfigKey">({ idKey: "checkConfigKey" }),
        withActiveId()
      )
    );
  }

  public updateFilter(item: Filter) {
    this.store.update((state) => ({
      ...state,
      filter: item
    }));
    this.fetchCheckConfigs();
  }

  public fetchCheckConfigs() {
    const filter = this.store.getValue().filter;
    this.apiService
      .searchCheckConfigs(this.rsql(filter).build())
      .pipe(this.trackRequest())
      .subscribe((checkConfigs) => this.set(checkConfigs as CheckConfig[]));
  }

  public getByKey(key: string): Observable<CheckConfigModel | undefined> {
    this.apiService.getCheckConfigByKey(key).subscribe((checkConfig) => this.upsert(checkConfig));
    return this.store.pipe(selectEntity(key));
  }

  public saveCheckConfig(key: string | undefined, model: CheckConfigModel) {
    if (key) {
      model.checkConfigKey = key;
      this.getAuditProperties(key, model as CheckConfig).subscribe(completeModel => {
        model = completeModel;
      });
    }
    //return this.apiService.saveCheckConfig(model).pipe(this.mapUpsert());
  }
}

import { Component, EventEmitter, Output } from "@angular/core";
import { CommonModule } from "@angular/common";

@Component({
  selector: "remainmybox-three-toggle",
  standalone: true,
  imports: [CommonModule],
  templateUrl: "./three-toggle.component.html",
  styleUrls: ["./three-toggle.component.scss"]
})
export class ThreeToggleComponent {
  value: boolean | undefined = undefined;
  selectedToggleValue = 0;

  @Output() toggleState = new EventEmitter<boolean | undefined>();

  onToggle(value: boolean | undefined): void {
    this.value = value;
    this.toggleState.emit(value);
  }
}

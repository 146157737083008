import { NgModule } from "@angular/core";
import { RouterModule, Routes } from "@angular/router";
import { AuthGuard } from "@remainmybox/auth";

const routes: Routes = [
  {
    path: "",
    pathMatch: "full",
    redirectTo: "/",
    title: "Landing"
  },
  {
    path: "container",
    loadChildren: () => import("@remainmybox/feature/hub/container").then((module) => module.FeatureHubContainerModule),
    canActivate: [AuthGuard],
    data: { roles: ["rmb_container_masterdata"], title: "Titles.hub.container" },
    title: "Container"
  },
  {
    path: "services",
    loadChildren: () => import("@remainmybox/feature/hub/services").then((module) => module.FeatureHubServicesModule),
    canActivate: [AuthGuard],
    data: { title: "Titles.hub.services" },
    title: "Services"
  },
  {
    path: "accounting",
    loadChildren: () => import("@remainmybox/feature/hub/accounting").then((module) => module.FeatureHubAccountingModule),
    canActivate: [AuthGuard],
    data: { title: "Titles.hub.accounting" },
    title: "Accounting"
  },
  {
    path: "masterdata",
    loadChildren: () => import("@remainmybox/feature/hub/masterdata").then((module) => module.FeatureHubMasterdataModule),
    canActivate: [AuthGuard],
    data: { roles: ["rmb_container_masterdata"], title: "Titles.hub.masterdata" },
    title: "Masterdata"
  },
  {
    path: "ctr-size",
    loadChildren: () => import("@remainmybox/feature/ctrsize").then((module) => module.FeatureCtrsizeModule),
    data: { roles: ["rmb_container_masterdata"] },
    title: "Container Size"
  },
  { path: "**", redirectTo: "/" }
];

@NgModule({
  imports: [RouterModule.forRoot([...routes])],
  exports: [RouterModule]
})
export class AppRoutingModule {}

import { APP_INITIALIZER, NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { KeycloakAngularModule, KeycloakBearerInterceptor, KeycloakService } from "keycloak-angular";
import { HTTP_INTERCEPTORS } from "@angular/common/http";
import { AuthGuard } from "./guards";

function initializeKeycloak(keycloak: KeycloakService) {
  const host = window.location.host;
  const dev = host.includes("localhost");
  const tenant = dev ? "t1" : host.split(".")[0];
  const keycloakBase = dev ? "login.dev-base.remainmybox.io" : host;
  return () =>
    keycloak.init({
      config: {
        url: "https://" + keycloakBase.replace(tenant, "login") + "/auth",
        realm: tenant,
        clientId: "remainmybox"
      },
      initOptions: {
        onLoad: "login-required",
        silentCheckSsoRedirectUri: host + "/assets/silent-check-sso.html",
        pkceMethod: "S256"
      },
      shouldAddToken: (request) => {
        return request.url.includes(".api.");
      }
    });
}

@NgModule({
  imports: [CommonModule, KeycloakAngularModule],
  providers: [
    AuthGuard,
    {
      provide: APP_INITIALIZER,
      useFactory: initializeKeycloak,
      multi: true,
      deps: [KeycloakService]
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: KeycloakBearerInterceptor,
      multi: true
    }
  ]
})
export class AuthModule {}

import { UntilDestroy } from "@ngneat/until-destroy";
import { Injectable } from "@angular/core";
import { DefaultService, EdiMsgReleaseModel } from "@remainmybox/api";
import { createStore, withProps } from "@ngneat/elf";
import {
  selectAllEntities,
  selectEntity,
  setEntities,
  upsertEntities,
  withActiveId,
  withEntities
} from "@ngneat/elf-entities";
import { Observable } from "rxjs";
import { EntityRepository } from "../../entity.repository";
import { Filter, Props } from "../../filter-components";

export interface EdiMsgRelease extends EdiMsgReleaseModel {
  ediMsgReleaseKey: string,
}

@UntilDestroy()
@Injectable({ providedIn: "root" })
export class EdiMsgReleaseRepository extends EntityRepository<EdiMsgRelease> {
  protected defaultSearchProperties: string[] = [];
  public readonly ediMsgReleases$: Observable<EdiMsgRelease[]> = this.store.pipe(selectAllEntities());

  constructor(private readonly apiService: DefaultService) {
    super(
      createStore(
        { name: "ediMsgReleases" },
        withProps<Props<EdiMsgReleaseModel>>({}),
        withEntities<EdiMsgRelease, "ediMsgReleaseKey">({ idKey: "ediMsgReleaseKey" }),
        withActiveId()
      )
    );
  }

  public updateFilter(item: Filter) {
    this.store.update(
      (state) => ({
        ...state,
        filter: item
      }));
    this.fetchEdiMsgReleases();
  }

  private fetchEdiMsgReleases() {
    this.apiService.searchEdiMsgReleases().subscribe(result => this.setEdiMsgReleases(result));
  }

  private setEdiMsgReleases(ediMsgReleases: EdiMsgReleaseModel[]) {
    this.store.update(
      setEntities(ediMsgReleases as EdiMsgRelease[])
    );
  }

  getAllByMsgRefKey(ediMsgRefId: string): Observable<EdiMsgReleaseModel[] | undefined> {
    this.apiService.getAllEdiMsgReleasesByMsgRefKey(ediMsgRefId).subscribe((items) => {
      this.store.update(upsertEntities(items as EdiMsgRelease[]));
    });
    return this.store
      .pipe(selectAllEntities());
  }

  getById(ediMsgRefId: string): Observable<EdiMsgReleaseModel | undefined> {
    this.apiService.getEdiMsgReleaseByKey(ediMsgRefId).subscribe((item) => {
      this.store.update(upsertEntities(item as EdiMsgRelease));
    });
    return this.store.pipe(selectEntity(ediMsgRefId));
  }
}

import { Injectable } from "@angular/core";
import { createStore, withProps } from "@ngneat/elf";
import { selectEntity, withActiveId, withEntities } from "@ngneat/elf-entities";
import { DefaultService, PickupPlaceModel } from "@remainmybox/api";
import { Observable } from "rxjs";
import { UntilDestroy } from "@ngneat/until-destroy";
import { EntityRepository } from "../entity.repository";
import { Filter, Props } from "../filter-components";

export interface PickupPlace extends PickupPlaceModel {
  pickupPlaceKey: string;
}

@UntilDestroy()
@Injectable({ providedIn: "root" })
export class PickupPlaceRepository extends EntityRepository<PickupPlace> {
  protected defaultSearchProperties: string[] = ["name", "remark"];

  constructor(private readonly apiService: DefaultService) {
    super(
      createStore(
        { name: "pickupPlaces" },
        withProps<Props<PickupPlaceModel>>({}),
        withEntities<PickupPlace, "pickupPlaceKey">({ idKey: "pickupPlaceKey" }),
        withActiveId()
      )
    );
  }

  public updateFilter(item: Filter) {
    this.store.update((state) => ({
      ...state,
      filter: item
    }));
    this.fetchPickupPlaces();
  }

  public fetchPickupPlaces() {
    const filter = this.store.getValue().filter;
    this.apiService
      .searchPickupPlaces(this.rsql(filter).build())
      .pipe(this.trackRequest())
      .subscribe((pickupPlaces) => this.set(pickupPlaces as PickupPlace[]));
  }

  public getByKey(key: string): Observable<PickupPlaceModel | undefined> {
    this.apiService.getPickupPlaceByKey(key).subscribe((pickupPlace) => this.upsert(pickupPlace));
    return this.store.pipe(selectEntity(key));
  }

  public savePickupPlace(key: string | undefined, model: PickupPlaceModel) {
    if (key) {
      model.pickupPlaceKey = key;
      this.getAuditProperties(key, model as PickupPlace).subscribe(completeModel => {
        model = completeModel;
      });
    }
    return this.apiService.savePickupPlace(model).pipe(this.mapUpsert());
  }
}

<div class="container font-normal text-sm bg-blue-light ">
  <div class="switch3-container">
    <div class="switch3">
<!--       not deleted ones-->
      <input type="radio" id="switch3-radio1" name="radio" (change)="onToggle(false)" />
      <label for="switch3-radio1" class="switch3-radio1">
        <i class="ph ph-minus"></i>
      </label>

<!--      everything-->
      <input type="radio" id="switch3-radio2" name="radio" checked="checked" (change)="onToggle(undefined)" />
      <label for="switch3-radio2" class="font-normal text-sm">Alle</label>

<!--      deleted ones-->
      <input type="radio" id="switch3-radio3" name="radio" (change)="onToggle(true)" />
      <label for="switch3-radio3">
        <i class="ph ph-check text-green"></i>
      </label>
    </div>
  </div>
</div>

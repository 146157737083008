import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { FormInputComponent } from "./form-input/form-input.component";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { FormSelectComponent } from "./form-select/form-select.component";
import { TranslocoModule } from "@ngneat/transloco";
import { FormTextareaComponent } from "./form-textarea/form-textarea.component";
import { NgSelectModule } from "@ng-select/ng-select";
import { FormCheckboxComponent } from "./form-checkbox/form-checkbox.component";
import { FormErrorComponent } from "./form-error/form-error.component";
import { ToggleComponent } from "./toggle/toggle.component";
import { FormButtonComponent } from "./form-button/form-button.component";
import { OverlayModule } from "@angular/cdk/overlay";
import { MatTooltipModule } from '@angular/material/tooltip';
import {ThreeToggleComponent} from "./form-three-toggle/three-toggle.component";

@NgModule({
  imports: [CommonModule, FormsModule, ReactiveFormsModule, TranslocoModule, NgSelectModule, OverlayModule, MatTooltipModule, ThreeToggleComponent],
  declarations: [
    FormInputComponent,
    FormSelectComponent,
    FormTextareaComponent,
    FormCheckboxComponent,
    FormErrorComponent,
    ToggleComponent,
    FormButtonComponent
  ],
  exports: [
    FormInputComponent,
    FormSelectComponent,
    FormTextareaComponent,
    FormCheckboxComponent,
    FormErrorComponent,
    ToggleComponent,
    FormButtonComponent,
    ThreeToggleComponent
  ],
})
export class UiFormElementsModule {}

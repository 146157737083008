import { Injectable } from "@angular/core";
import { createStore, withProps } from "@ngneat/elf";
import { selectEntity, setEntities, upsertEntities, withActiveId, withEntities } from "@ngneat/elf-entities";

import { DefaultService, InternalOrderFacilityMappingModel, InternalOrderModel } from "@remainmybox/api";
import { EMPTY, from, mergeMap, Observable } from "rxjs";
import { UntilDestroy, untilDestroyed } from "@ngneat/until-destroy";
import { InternalOrderFacilityRepository } from "./internal-order-facility.repository";
import { EntityRepository } from "../entity.repository";
import { Filter, Props } from "../filter-components";

export interface InternalOrder extends InternalOrderModel {
  internalOrderKey: string;
}


@UntilDestroy()
@Injectable({ providedIn: "root" })
export class InternalOrderRepository extends EntityRepository<InternalOrder> {
  protected defaultSearchProperties: string[] = ["text", "remark"];

  constructor(
    private readonly apiService: DefaultService,
    private readonly internalOrderFacilityRepository: InternalOrderFacilityRepository
  ) {
    super(
      createStore(
        { name: "internal-order" },
        withProps<Props<InternalOrderModel>>({}),
        withEntities<InternalOrder, "internalOrderKey">({ idKey: "internalOrderKey" }),
        withActiveId()
      )
    );
  }

  public updateFilter(item: Filter) {
    this.store.update((state) => ({
      ...state,
      filter: item
    }));
    this.fetchInternalOrders();
  }

  private setInternalOrders(internalOrders: InternalOrderModel[]) {
    this.store.update(setEntities(internalOrders as InternalOrder[]));
  }

  public fetchInternalOrders() {
    const filter = this.store.getValue().filter;
    this.apiService
      .searchInternalOrders(this.rsql(filter).build())
      .pipe(this.trackRequest())
      .subscribe((result) => this.setInternalOrders(result));
  }

  public saveInternalOrder(
    id: string | undefined,
    iOrder: InternalOrderModel,
    iOrderFacilities?: InternalOrderFacilityMappingModel[]
  ) {
    if (id) {
      iOrder.internalOrderKey = id;
    }
    // iOrder.internalOrderFacilities = iOrderFacilities;

    return this.apiService.saveInternalOrder(iOrder).pipe(
      untilDestroyed(this),
      mergeMap((savedOrder) => {
        this.mapUpsert(); //update the repo with the saved order
        return iOrderFacilities && iOrderFacilities?.length > 0
          ? from(iOrderFacilities).pipe(
            mergeMap((iFacility) => {
              iFacility.internalOrder = savedOrder;
              return this.saveFacility(iFacility); //save the children
            })
          )
          : EMPTY;
      })
    );
  }

  private saveFacility(facility: InternalOrderFacilityMappingModel | undefined) {
    return facility ? this.internalOrderFacilityRepository.prepareForSaving(facility) : EMPTY;
  }

  public getByKey(key: string): Observable<InternalOrderModel | undefined> {
    this.apiService
      .getInternalOrderByKey(key)
      .subscribe((item) => this.store.update(upsertEntities(item as InternalOrder)));
    return this.store.pipe(selectEntity(key));
  }
}

<div class="relative mb-4 {{ outerDivClasses }}" *transloco="let t">
  <label
  *ngIf="useLabel"
  [for]="name || formControlName"
  class="text-xs font-normal text-gray dark:text-white leading-none"
>{{ t(label) }}</label>
  <ng-select #internalSelectField
             [ngClass]="{'bg-mandatory': required,
               'search-input': useLabel,
               'ng-select-custom': required,
               'w-[45px]': inputSize === 'small',
               'w-[75px]': inputSize === 'medium',
               'w-[125px]': inputSize === 'large',
               'hovered': isHovered,
               'border-gray-mid': !isHovered}"
             class="select block peer"
             [items]="dataSource"
             [clearable]="false"
             [bindLabel]="optionDisplay"
             [bindValue]="optionValue"
             (change)="onSelectionChanged($event)"
             (blur)="onSelectTouched()"
             [(ngModel)]="selectedItem"
             [compareWith]="compareItems"
             [disabled]="disabled"
             [placeholder]="placeholder"
             [id]="name || formControlName"
  >
    <ng-template *ngIf="translationPrefix" ng-option-tmp ng-label-tmp let-item="item">
        <span *ngIf="item">
          {{ t(translationPrefix + item) }}
        </span>
    </ng-template>
  </ng-select>
  <remainmybox-form-error
    [error]="error" *ngIf="control?.touched && control?.errors && error">
  </remainmybox-form-error>
</div>

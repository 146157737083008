import { Injectable } from "@angular/core";
import { createStore, withProps } from "@ngneat/elf";
import { selectAllEntities, setActiveId, setEntities, withActiveId, withEntities } from "@ngneat/elf-entities";

import { DefaultService, SiteModel } from "@remainmybox/api";
import { Observable } from "rxjs";
import { UntilDestroy } from "@ngneat/until-destroy";
import { RsqlBuilder } from "../rsql-builder";
import { EntityRepository } from "../entity.repository";
import { Filter, Props } from "../filter-components";

export interface Site extends SiteModel {
  siteKey: string;
}

export interface SiteFilter extends Filter {
  type?: SiteModel;
}

export interface SiteProps extends Props<SiteModel> {
  filter?: SiteFilter;
}

@UntilDestroy()
@Injectable({ providedIn: "root" })
export class SiteRepository extends EntityRepository<Site> {
  protected defaultSearchProperties: string[] = ["name", "nameDe", "cd"];
  public readonly sites$: Observable<SiteModel[]> = this.store.pipe(selectAllEntities());

  //public readonly active$: Observable<SiteModel | undefined> = this.store.pipe(selectActiveEntity());

  constructor(private readonly apiService: DefaultService) {
    super(
      createStore(
        { name: "sites" },
        withProps<SiteProps>({}),
        withEntities<Site, "siteKey">({
          idKey: "siteKey"
        }),
        withActiveId()
      )
    );
  }

  public updateFilter(item: SiteFilter) {
    this.store.update(
      (state) => ({
        ...state,
        filter: item
      }));
  }

  public override setActive(id: string) {
    this.store.update(setActiveId(id));
  }

  public fetchSites() {
    const filter = this.store.getValue().filter;
    this.apiService
      .searchSites(this.rsql(filter).build())
      .subscribe((result) => this.setSites(result));
  }

  private setSites(sites: SiteModel[]) {
    this.store.update(
      setEntities(sites as Site[])
    );
  }

  override rsql(filter: SiteFilter | undefined): RsqlBuilder {
    return super.rsql(filter)
      .eq("type", filter?.type);
  }
}


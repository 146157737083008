<header class="pl-8 pr-8 flex flex-row flex-wrap items-stretch justify-between" *transloco="let t">
  <div class="w-1/5 flow-root py-8">
    <button
      [cdkMenuTriggerFor]="profileDropdown"
      [cdkMenuPosition]="[
        {
          originX: 'end',
          originY: 'bottom',
          overlayX: 'end',
          overlayY: 'top',
          offsetY: 8,
          offsetX: 8
        }
      ]"
      class="btn ml-8 mr-8 float-right"
    >
      <svg-icon src="assets/icons/profile-circled.svg"></svg-icon>
    </button>
  </div>
</header>

<!-- Menu dropdown -->
<ng-template #menuDropdown>
  <div class="dropdown dropdown-left dropdown-large mt-[4px]" cdkMenu *transloco="let t">
    <ng-container *ngFor="let item of navigationItems">
      <div *ngIf="item?.children?.length; else menuItemWithNoChilds">
        <a
          cdkMenuItem
          class="dropdown-item flex items-center width-auto justify-start"
          (mouseenter)="selectedMenuPoint = item"
          [cdkMenuTriggerFor]="navigationSubMenu"
        >
          <div class="flex flex-row items-center justify-start">
            <svg-icon
              [svgStyle]="{ 'width.px': 30 }"
              *ngIf="item.icon"
              class="ml-2 mr-8"
              [src]="'assets/icons/' + item.icon"
            ></svg-icon>
            <span class="truncate">{{ t(item.name) }}</span>
            <div class="ml-16 mr-8" *ngIf="item?.children?.length">
              <svg-icon svgClass="rotate-270" src="assets/icons/nav-arrow-down.svg"></svg-icon>
            </div>
          </div>
        </a>
      </div>
      <ng-template #menuItemWithNoChilds>
        <a
          cdkMenuItem
          class="dropdown-item flex flex-row items-center justify-start"
          (mouseenter)="selectedMenuPoint = item"
          (click)="item.onClick ? item.onClick() : ''"
        >
          <div class="flex flex-row items-center justify-start">
            <svg-icon
              [svgStyle]="{ 'width.px': 30 }"
              *ngIf="item.icon"
              class="ml-2 mr-8"
              [src]="'assets/icons/' + item.icon"
            ></svg-icon>
            <span class="truncate">{{ t(item.name) }}</span>
          </div>
        </a>
      </ng-template>
    </ng-container>
  </div>
</ng-template>

<!-- Profile dropdown -->
<ng-template #profileDropdown>
  <div class="dropdown dropdown-right mt-[8px]" cdkMenu *transloco="let t">
    <button (click)="editProfile()" cdkMenuItem class="dropdown-item pt-4 pb-4 flex items-center">
      <svg-icon [svgStyle]="{ 'width.px': 24 }" class="ml-2 mr-8" src="assets/icons/profile-circled.svg"></svg-icon>
      <!-- temporary -->
      Profile
    </button>
    <button [cdkMenuTriggerFor]="languageDropdown" cdkMenuItem class="dropdown-item pt-4 pb-4 flex items-center">
      <svg-icon [svgStyle]="{ 'width.px': 24 }" class="ml-2 mr-8" src="assets/icons/language.svg"></svg-icon>
      <!-- temporary -->
      Language: German
    </button>
    <!--<div class="toggle pt-4 pb-4 flex">
      <remainmybox-form-toggle
        (changes)="onToggle()"
        label="{{ t('Settings.darkMode')}}"
        [checked]="darkModeChecked"
    ></remainmybox-form-toggle>
    </div>-->
    <div class="toggle pt-4 pb-4 flex">
      <remainmybox-form-toggle
        (changes)="onTabsToggle()"
        label="{{ t('Settings.tabs')}}"
        [checked]="tabsChecked"
    ></remainmybox-form-toggle>
    </div>
    <button cdkMenuItem class="dropdown-item pt-4 pb-4 flex items-center">
      <svg-icon [svgStyle]="{ 'width.px': 24 }" class="ml-2 mr-8" src="assets/icons/logout.svg"></svg-icon>

      <!-- temporary -->
      Logout
    </button>
  </div>
</ng-template>

<ng-template #languageDropdown>
  <div class="dropdown dropdown-right" cdkMenu *transloco="let t">
    <ng-container *ngFor="let language of languages">
      <button
        cdkMenuItem
        [ngClass]="{ active: selectedLanguage === language }"
        class="dropdown-item pt-4 pb-4 flex items-center"
      >
        <!-- Temporary -->
        {{ t("languages." + language) }}
      </button>
    </ng-container>
  </div>
</ng-template>

<ng-template #navigationSubMenu>
  <div class="dropdown dropdown-left" cdkMenu>
    <ng-container *ngFor="let item of selectedMenuPoint?.children">
      <div>
        <a cdkMenuItem class="dropdown-item flex flex-row items-center justify-between" (click)="item?.onClick()">
          <div class="flex flex-row items-center justify-between">
            <svg-icon
              [svgStyle]="{ 'width.px': 30 }"
              *ngIf="item.icon"
              class="ml-2 mr-8"
              [src]="'assets/images/svg/' + item.icon"
            ></svg-icon>
            <span class="truncate">{{ item.name }}</span>
          </div>
        </a>
      </div>
    </ng-container>
  </div>
</ng-template>

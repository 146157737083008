<div
  [ngClass]="{ 'w-60': isOpened, 'w-16': !isOpened }"
  class="h-full min-h-screen relative top-0 left-0 bg-blue text-white transition-all duration-300 z-10 flex flex-col justify-between min-w-min"
>
  <div class="flex justify-start items-center p-4 mb-8">
    <button (click)="toggleSidebar()" class="text-white text-l">
      <i [class]="isOpened ? 'ph ph-caret-double-left' : 'ph ph-caret-double-right'"></i>
    </button>
  </div>
  <div class="overflow-auto flex-1 flex flex-col">
    <ul class="list-none p-0 m-0">
      <ng-container *ngTemplateOutlet="listEntry; context: { menuItems, showSpacer: true, level: 1 }"> </ng-container>
    </ul>
  </div>
  <div
    [ngClass]="{ 'invisible w-0': !isOpened }"
    class="p-4 text-center text-xs border-t border-blue-light border-opacity-50"
  >
    <!--TODO: replace with actual variables-->
    <p>DEV-VERSION</p>
    <p>Administrator | EUROGATE Container Terminal Hamburg | DEV-ENV</p>
  </div>
</div>

<ng-template #listEntry let-items="menuItems" let-showSpacer="showSpacer" let-level="level">
  <ng-container *transloco="let t">
    <li *ngFor="let item of items; let first = first; let last = last">
      <button
        *ngIf="!item.link; else navigationLink"
        [ngClass]="{ 'border-t border-blue-light border-opacity-50': showSpacer }"
        class="w-full flex items-center gap-4 px-4 py-2 text-left hover:bg-blue-hover break-words whitespace-normal"
        (click)="activateItem(item)"
      >
        <i [ngClass]="{ 'text-2xl': level === 1 }" [class]="item.icon"></i>
        <span
          [ngClass]="{ 'text-sm': level > 1 }"
          class="break-words whitespace-normal text-wrap overflow-auto flex-1"
          *ngIf="isOpened"
          >{{ item.translate ? t(item.label) : item.label }}</span
        >
        <i
          *ngIf="isOpened && item?.submenus?.length"
          [class]="item.isActive ? 'ph ph-caret-up' : 'ph ph-caret-down'"
          class="ml-auto"
        ></i>
      </button>
      <ng-template #navigationLink>
        <div class="w-full flex items-center gap-4 py-2 text-left hover:bg-blue-hover">
          <i class="ph"></i>
          <remainmybox-nav-link
            [ngClass]="{ 'text-sm': level > 1 }"
            [link]="item.link"
            [translationKey]="item.label"
          ></remainmybox-nav-link>
          <i class="ml-auto"></i>
        </div>
      </ng-template>

      <ng-container *ngIf="item.isActive && item?.submenus?.length">
        <ul [ngStyle]="{ 'padding-left.px': 24 + level * 9 }">
          <ng-container
            *ngTemplateOutlet="listEntry; context: { menuItems: item.submenus, showSpacer: false, level: level + 1 }"
          >
          </ng-container>
        </ul>
      </ng-container>
    </li>
  </ng-container>
</ng-template>

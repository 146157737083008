import { Injectable } from "@angular/core";
import { createStore, withProps } from "@ngneat/elf";
import { selectEntity, withActiveId, withEntities } from "@ngneat/elf-entities";
import { DefaultService, LocationBlockModel } from "@remainmybox/api";
import { Observable } from "rxjs";
import { UntilDestroy } from "@ngneat/until-destroy";
import { EntityRepository } from "../entity.repository";
import { Filter, Props } from "../filter-components";

export interface LocationBlock extends LocationBlockModel {
  locationBlockKey: string;
}

@UntilDestroy()
@Injectable({ providedIn: "root" })
export class LocationBlockRepository extends EntityRepository<LocationBlock> {
  protected defaultSearchProperties: string[] = ["text", "remark"];

  constructor(private readonly apiService: DefaultService) {
    super(
      createStore(
        { name: "locationBlocks" },
        withProps<Props<LocationBlockModel>>({}),
        withEntities<LocationBlock, "locationBlockKey">({ idKey: "locationBlockKey" }),
        withActiveId()
      )
    );
  }

  public updateFilter(item: Filter) {
    this.store.update((state) => ({
      ...state,
      filter: item
    }));
    this.fetchLocationBlocks();
  }

  public fetchLocationBlocks() {
    const filter = this.store.getValue().filter;
    this.apiService
      .searchLocationBlocks(this.rsql(filter).build())
      .pipe(this.trackRequest())
      .subscribe((locationBlocks) => this.set(locationBlocks as LocationBlock[]));
  }

  public getByKey(key: string): Observable<LocationBlockModel | undefined> {
    this.apiService.getLocationBlockByKey(key).subscribe((locationBlock) => this.upsert(locationBlock));
    return this.store.pipe(selectEntity(key));
  }

  public saveLocationBlock(key: string | undefined, model: LocationBlockModel) {
    if (key) {
      model.locationBlockKey = key;
      this.getAuditProperties(key, model as LocationBlock).subscribe(completeModel => {
        model = completeModel;
      });
    }
    return this.apiService.saveLocationBlock(model).pipe(this.mapUpsert());
  }
}

<div class="relative mb-4" *transloco="let t">
  <label
    [for]="name || formControlName"
    class="text-xs font-normal text-gray dark:text-white leading-none"
    [ngClass]="{ 'border-red': control?.touched && control?.errors && error }"
    >{{ t(label) }}</label
  >
  <div class="relative">
    <textarea
      #myTextArea
      [ngClass]="inputClasses"
      type="text"
      rows="6"
      [(ngModel)]="value"
      (change)="inputChanged()"
      (keyup)="onKeyup($event)"
      (blur)="markInputAsTouched()"
      [disabled]="disabled"
      [id]="name || formControlName"
      placeholder=" "
    ></textarea>
    <i *ngIf="control?.touched && control?.errors && error" [ngClass]="errorIconClasses"></i>
    <i *ngIf="hasCopyIcon" [ngClass]="copyIconClasses"></i>
    <i *ngIf="hasPencilIcon" [ngClass]="pencilIconClasses"></i>
  </div>
  <remainmybox-form-error [error]="error" *ngIf="control?.touched && control?.errors && error"></remainmybox-form-error>
</div>

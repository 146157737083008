import { AbstractControl, ValidationErrors, ValidatorFn, Validators } from "@angular/forms";

export class RmbValidators {
  static readonly PATTERN = "pattern";

  static isNumber(): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      const errors = Validators.pattern(/^[0-9]+$/)(control);
      // Change the key to match our translation key
      if (errors && errors[RmbValidators.PATTERN]) {
        errors["validation.isNumber"] = errors[RmbValidators.PATTERN];
        delete errors[RmbValidators.PATTERN];
        return errors;
      }
      return null;
    };
  }
}

import { Injectable } from "@angular/core";
import { createStore, withProps } from "@ngneat/elf";
import {
  addEntities,
  selectEntity,
  setActiveId,
  setEntities,
  upsertEntities,
  withActiveId,
  withEntities
} from "@ngneat/elf-entities";

import { CustomerModel, DefaultService } from "@remainmybox/api";
import { map, Observable, switchMap } from "rxjs";
import { UntilDestroy } from "@ngneat/until-destroy";
import { RsqlBuilder } from "../../rsql-builder";
import { EntityRepository } from "../../entity.repository";
import { Filter, Props } from "../../filter-components";

export interface Customer extends CustomerModel {
  customerKey: string;
}

export interface CustomerFilter extends Filter {
  deleted?: boolean;
}

export interface CustomerProps extends Props<CustomerModel> {
  filter?: CustomerFilter;
}

@UntilDestroy()
@Injectable({ providedIn: "root" })
export class CustomerRepository extends EntityRepository<Customer> {
  public readonly customers$ = this.all$;
  protected defaultSearchProperties: string[] = ["customerId", "name"];

  constructor(private readonly apiService: DefaultService) {
    super(
      createStore(
        { name: "customers" },
        withProps<CustomerProps>({}),
        withEntities<Customer, "customerKey">({ idKey: "customerKey" }),
        withActiveId()
      )
    );
  }

  private setCustomers(customers: CustomerModel[]) {
    this.store.update(setEntities(customers as Customer[]));
  }

  public fetchCustomers() {
    const filter = this.store.getValue().filter;

    this.apiService
      .searchCustomers(this.rsql(filter).build())
      .subscribe((result) => this.setCustomers(result));
  }

  getByKey(customerKey: string): Observable<CustomerModel | undefined> {
    this.apiService
      .getCustomerByKey(customerKey)
      .subscribe((item) => this.store.update(upsertEntities(item as Customer)));
    return this.store.pipe(selectEntity(customerKey));
  }

  public updateFilter(item: CustomerFilter) {
    this.store.update((state) => ({
      ...state,
      filter: item
    }));
    this.fetchCustomers();
  }

  public saveCustomer(key: string | undefined, model: CustomerModel) {
    if (key) {
      model.customerKey = key;
      this.getAuditProperties(key, model as Customer).subscribe(completeModel => {
        model = completeModel;
      });

      return this.apiService.getCustomerByKey(key).pipe(
        // FIXME to get this working model must contain paymentTerm. It gets lost somehow...
        map((freshCustomer) => ({ ...freshCustomer, ...model })),
        switchMap((customer) => this.apiService.saveCustomer(customer)),
        this.mapUpsert()
      );
    }

    return this.apiService.saveCustomer(model).pipe(this.mapUpsert());
  }

  public createCustomer(): void {
    this.store.update(addEntities([{ customerKey: "", deleted: false } as Customer]), setActiveId(""));
  }

  override rsql(filter: CustomerFilter | undefined): RsqlBuilder {
    return super.rsql(filter)
      .neq("deleted", !filter?.deleted);
  }
}

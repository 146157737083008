import { Injectable } from "@angular/core";
import { createStore, withProps } from "@ngneat/elf";
import {
  selectAllEntities,
  selectEntity,
  setEntities,
  upsertEntities,
  withActiveId,
  withEntities
} from "@ngneat/elf-entities";

import { CustomerContactModel, DefaultService } from "@remainmybox/api";
import { Observable } from "rxjs";
import { UntilDestroy } from "@ngneat/until-destroy";
import { EntityRepository } from "../../entity.repository";
import { Filter, Props } from "../../filter-components";

export interface CustomerContact extends CustomerContactModel {
  customerContactKey: string;
}


@UntilDestroy()
@Injectable({ providedIn: "root" })
export class CustomerContactRepository extends EntityRepository<CustomerContact> {
  protected defaultSearchProperties: string[] = ["customer.customerKey"];
  public readonly customerContacts$: Observable<CustomerContactModel[]> = this.store.pipe(selectAllEntities());

  constructor(private readonly apiService: DefaultService) {
    super(
      createStore(
        { name: "customerContacts" },
        withProps<Props<CustomerContactModel>>({}),
        withEntities<CustomerContact, "customerContactKey">({ idKey: "customerContactKey" }),
        withActiveId()
      )
    );
  }

  public updateFilter(item: Filter) {
    this.store.update((state) => ({
      ...state,
      filter: item
    }));
    this.fetchCustomerContacts();
  }

  private setCustomerContacts(customerContacts: CustomerContactModel[]) {
    this.store.update(setEntities(customerContacts as CustomerContact[]));
  }

  public fetchCustomerContacts() {
    const filter = this.store.getValue().filter;
    this.apiService
      .searchCustomerContacts(this.rsql(filter).build())
      .subscribe((result) => this.setCustomerContacts(result));
  }

  public getByKey(key: string): Observable<CustomerContactModel | undefined> {
    this.apiService
      .getCustomerContactByKey(key)
      .subscribe((item) => this.store.update(upsertEntities(item as CustomerContact)));
    return this.store.pipe(selectEntity(key));
  }

  public saveCustomerContact(key: string | undefined, model: CustomerContactModel) {
    if (key) {
      model.customerContactKey = key;
      this.getAuditProperties(key, model as CustomerContact).subscribe(completeModel => {
        model = completeModel;
      });
    }
    return this.apiService
      .saveCustomerContact(model)
      .subscribe((saved) => this.store.update(upsertEntities(saved as CustomerContact)));
  }
}

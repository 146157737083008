import { APP_INITIALIZER, NgModule } from "@angular/core";
import { BrowserModule } from "@angular/platform-browser";

import { AppComponent } from "./app.component";

import { TranslocoRootModule } from "./transloco-root.module";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { ReactiveFormsModule } from "@angular/forms";
import { HttpClientModule } from "@angular/common/http";
import { AppRoutingModule } from "./app-routing.module";
import { environment } from "../environments/environment";
import { devTools } from "@ngneat/elf-devtools";
import { UiHeaderModule } from "@remainmybox/ui/header";
import { AuthModule } from "@remainmybox/auth";
import { AngularSvgIconModule } from "angular-svg-icon";
import { ApiModule, Configuration } from "@remainmybox/api";
import { UiPopupModule } from "@remainmybox/ui/popup";
import { httpInterceptorProviders } from "./interceptors";
import dayjs from "dayjs/esm";
import "dayjs/esm/locale/de";
import localizedFormat from "dayjs/esm/plugin/localizedFormat";
import updateLocale from "dayjs/esm/plugin/updateLocale";
import localeData from "dayjs/esm/plugin/localeData";
import { popperVariation, provideTippyConfig, tooltipVariation } from "@ngneat/helipopper";
import { provideAnimationsAsync } from "@angular/platform-browser/animations/async";
import { UiSidebarComponent } from "ui/sidebar";
import { provideHotToastConfig } from "@ngxpert/hot-toast";
import { SharedServicesModule } from "@remainmybox/shared/services";
import { CtrlClickDirective } from "@remainmybox/shared/directives";
import { LibHeaderComponent } from "lib-header";

function initElfDevTools() {
  return () => {
    if (!environment.production) {
      devTools();
    }
  };
}

function initDayjs() {
  return () => {
    dayjs.extend(localizedFormat);
    dayjs.extend(updateLocale);
    dayjs.extend(localeData);
    dayjs().localeData();
  };
}

export function apiConfigFactory(): Configuration {
  const host = window.location.host;
  const dev = host.includes("localhost");
  const tenant = dev ? "t1" : host.split(".")[0];
  const apiBase = dev ? "t1.api.dev-base.remainmybox.io" : host.replace(tenant, tenant + ".api");

  const isLocalDatabase = environment.localDatabase || false;
  const localDatabaseAddress = environment.localDatabaseAddress;

  const basePath = isLocalDatabase ? localDatabaseAddress : "https://" + apiBase;
  // const basePath = "https://" + "t1.api.dev-base.remainmybox.io";
  return new Configuration({
    basePath: basePath
  });
}

@NgModule({
  declarations: [AppComponent, CtrlClickDirective],
  imports: [
    AppRoutingModule,
    BrowserAnimationsModule,
    BrowserModule,
    HttpClientModule,
    ReactiveFormsModule,
    UiHeaderModule,
    UiPopupModule,
    //SharedLoadingSpinnerModule,
    AngularSvgIconModule.forRoot(),
    TranslocoRootModule,
    AuthModule,
    ApiModule.forRoot(apiConfigFactory),
    UiSidebarComponent,
    SharedServicesModule,
    LibHeaderComponent
  ],
  providers: [
    {
      provide: APP_INITIALIZER,
      multi: true,
      useFactory: initElfDevTools
    },
    {
      provide: APP_INITIALIZER,
      multi: true,
      useFactory: initDayjs
    },
    httpInterceptorProviders,
    provideTippyConfig({
      defaultVariation: "tooltip",
      variations: {
        tooltip: { ...tooltipVariation, delay: 1000 },
        popper: popperVariation
      }
    }),
    provideAnimationsAsync(),
    provideHotToastConfig()
  ],
  bootstrap: [AppComponent]
})
export class AppModule {}

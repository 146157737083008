import { UntilDestroy } from "@ngneat/until-destroy";
import { Injectable } from "@angular/core";
import { DefaultService, EdiMsgCodecoModel } from "@remainmybox/api";
import { createStore, withProps } from "@ngneat/elf";
import {
  selectAllEntities,
  selectEntity,
  setEntities,
  upsertEntities,
  withActiveId,
  withEntities
} from "@ngneat/elf-entities";
import { Observable } from "rxjs";
import { EntityRepository } from "../../entity.repository";
import { Filter, Props } from "../../filter-components";

export interface EdiMsgCodeco extends EdiMsgCodecoModel {
  ediMsgCodecoKey: string,
}


@UntilDestroy()
@Injectable({ providedIn: "root" })
export class EdiMsgCodecoRepository extends EntityRepository<EdiMsgCodeco> {
  protected defaultSearchProperties: string[] = [];

  public readonly ediMsgCodecos$: Observable<EdiMsgCodeco[]> = this.store.pipe(selectAllEntities());

  constructor(private readonly apiService: DefaultService) {
    super(createStore(
        { name: "ediMsgCodecos" },
        withProps<Props<EdiMsgCodecoModel>>({}),
        withEntities<EdiMsgCodeco, "ediMsgCodecoKey">({ idKey: "ediMsgCodecoKey" }),
        withActiveId()
      )
    );
  }

  public updateFilter(item: Filter) {
    this.store.update(
      (state) => ({
        ...state,
        filter: item
      }));
    this.fetchEdiMsgCodecos();
  }

  private fetchEdiMsgCodecos() {
    const filter = this.store.getValue().filter;
    this.apiService.searchEdiMsgCodecos(this.rsql(filter).build()).subscribe(result => this.setEdiMsgCodecos(result));
  }

  private setEdiMsgCodecos(ediMsgCodecos: EdiMsgCodecoModel[]) {
    this.store.update(
      setEntities(ediMsgCodecos as EdiMsgCodeco[])
    );
  }

  getAllByMsgRefId(ediMsgRefId: string): Observable<EdiMsgCodecoModel[] | undefined> {
    this.apiService.getAllEdiMsgCodecosByMsgRefKey(ediMsgRefId).subscribe((items) => {
      this.store.update(upsertEntities(items as EdiMsgCodeco[]));
    });
    return this.store
      .pipe(selectAllEntities());
  }

  getById(ediMsgRefId: string): Observable<EdiMsgCodecoModel | undefined> {
    this.apiService.getEdiMsgCodecoByKey(ediMsgRefId).subscribe((item) => {
      this.store.update(upsertEntities(item as EdiMsgCodeco));
    });
    return this.store.pipe(selectEntity(ediMsgRefId));
  }
}

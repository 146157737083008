import { ChangeDetectorRef, Component, ElementRef, Input, OnInit, Optional } from "@angular/core";
import { AbstractValueAccessorComponent } from "../abstract-value-accessor.component";
import { ValueAccessorUtil } from "../value-accessor.util";
import { ControlContainer } from "@angular/forms";

@Component({
  selector: "remainmybox-form-checkbox",
  templateUrl: "./form-checkbox.component.html",
  styleUrls: ["./form-checkbox.component.scss"],
  providers: [...ValueAccessorUtil.getValueAccessorProvider(FormCheckboxComponent)]
})
export class FormCheckboxComponent extends AbstractValueAccessorComponent<string | number> implements OnInit {
  outerDivClasses = "";
  
  constructor(
    protected override changeDetectorRef: ChangeDetectorRef,
    @Optional() protected override _controlContainer: ControlContainer,
    private elementRef: ElementRef
  ) {
    super(changeDetectorRef, _controlContainer);
  }

  inputChanged(): void {
    this.onChange(this.value);
    this.formControl?.setValue(this.value);
    this.changed.emit(this.value);
  }

  ngOnInit() {
    // If this checkbox is inside an accordion item body, add margin to the outer div
    const accordionElement = this.elementRef.nativeElement.closest('.accordion-item-body');
    const tableElement = this.elementRef.nativeElement.closest('table');
    if (accordionElement && !tableElement) {
      this.outerDivClasses = "mt-[2rem] mb-6";
    }
  }
}

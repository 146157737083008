import { UntilDestroy } from "@ngneat/until-destroy";
import { Injectable } from "@angular/core";
import { DefaultService, EdiMsgEstimateModel } from "@remainmybox/api";
import { createStore, withProps } from "@ngneat/elf";
import {
  selectAllEntities,
  selectEntity,
  setEntities,
  upsertEntities,
  withActiveId,
  withEntities
} from "@ngneat/elf-entities";
import { Observable } from "rxjs";
import { EntityRepository } from "../../entity.repository";
import { Filter, Props } from "../../filter-components";

export interface EdiMsgEstimate extends EdiMsgEstimateModel {
  ediMsgEstimateKey: string,
}


@UntilDestroy()
@Injectable({ providedIn: "root" })
export class EdiMsgEstimateRepository extends EntityRepository<EdiMsgEstimate> {
  protected defaultSearchProperties: string[] = [];
  public readonly ediMsgEstimates$: Observable<EdiMsgEstimate[]> = this.store.pipe(selectAllEntities());

  constructor(private readonly apiService: DefaultService) {
    super(
      createStore(
        { name: "ediMsgEstimates" },
        withProps<Props<EdiMsgEstimateModel>>({}),
        withEntities<EdiMsgEstimate, "ediMsgEstimateKey">({ idKey: "ediMsgEstimateKey" }),
        withActiveId()
      )
    );
  }

  public updateFilter(item: Filter) {
    this.store.update(
      (state) => ({
        ...state,
        filter: item
      }));
    this.fetchEdiMsgEstimates();
  }

  private fetchEdiMsgEstimates() {
    this.apiService.searchEdiMsgEstimates().subscribe(result => this.setEdiMsgEstimates(result));
  }

  private setEdiMsgEstimates(ediMsgEstimates: EdiMsgEstimateModel[]) {
    this.store.update(
      setEntities(ediMsgEstimates as EdiMsgEstimate[])
    );
  }

  getAllByMsgRefKey(ediMsgRefId: string): Observable<EdiMsgEstimateModel[] | undefined> {
    this.apiService.getAllEdiMsgEstimatesByMsgRefKey(ediMsgRefId).subscribe((items) => {
      this.store.update(upsertEntities(items as EdiMsgEstimate[]));
    });
    return this.store
      .pipe(selectAllEntities());
  }

  getById(ediMsgRefId: string): Observable<EdiMsgEstimateModel | undefined> {
    this.apiService.getEdiMsgEstimateByKey(ediMsgRefId).subscribe((item) => {
      this.store.update(upsertEntities(item as EdiMsgEstimate));
    });
    return this.store.pipe(selectEntity(ediMsgRefId));
  }
}

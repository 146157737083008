import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { PopupComponent } from "./popup/popup.component";
import { DialogModule } from "@angular/cdk/dialog";
import { TranslocoModule } from "@ngneat/transloco";

@NgModule({
  imports: [CommonModule, DialogModule, TranslocoModule],
  declarations: [PopupComponent],
  exports: [PopupComponent]
})
export class UiPopupModule {}

import { Inject, Injectable } from "@angular/core";
import { UntilDestroy } from "@ngneat/until-destroy";
import { HotToastService } from '@ngxpert/hot-toast';
import { Content } from "@ngneat/overview";
import { ErrorIconComponent, InfoIconComponent, SuccessIconComponent } from "@remainmybox/ui/icons";

export enum ToastType {
    Success = 'success',
    Error = 'error',
    Info = 'info'
}

/**
 * Service for showing toasts
 * 
 * Import this with 
 * 
 * import { ToastService } from '@remainmybox/shared/services';
 * 
 * @example
 * this.toastService.showToast(ToastType.Success, "Headline", "Message");
 * 
 */
@Injectable({ providedIn: "root" })
@UntilDestroy()
export class ToastService {

    constructor(private readonly hotToastService: HotToastService, @Inject('TOKENS') private tokens: any) {
    }

    public showToast(type: ToastType, headline: string, message?: string) {
        this.hotToastService.show(`
    ${headline}
    ${message ? `<br><br><p>${message}</p>` : ''}
  `, {
            icon: this.getIcon(type), style: this.getStyle(type),
            className: 'hot-toast-message-custom',
            dismissible: true,
            autoClose: true,
            position: "top-right"
        })
    }

    private getIcon(type: ToastType): Content {
        switch (type) {
            case ToastType.Success:
                return SuccessIconComponent;
            case ToastType.Error:
                return ErrorIconComponent;
            case ToastType.Info:
                return InfoIconComponent;
        }
    }

    private getStyle(type: ToastType): Record<string, string> {
        const baseStyle = {
            boxSizing: 'unset',
            padding: '16px',
            color: `${this.tokens.color.primary.black.value}`,
            width: '400px',
            borderRadius: '4px',
            fontFamily: `${this.tokens.font.textMSemibold.fontFamily.value}`,
            fontWeight: `${this.tokens.font.textMSemibold.fontWeight.value}`,
            lineHeight: `${this.tokens.font.textMSemibold.lineHeight.value}`,
        };
    
    const typeSpecificStyle = {
        [ToastType.Success]: { border: `1px solid ${this.tokens.color.green.value}` },
        [ToastType.Error]: { border: `1px solid ${this.tokens.color.red.value}` },
        [ToastType.Info]: { border: `1px solid ${this.tokens.color.primary.blue.value}` },
    };
    
        return { ...baseStyle, ...typeSpecificStyle[type] };
    }
}